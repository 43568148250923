<template>
  <div class="all_wrap">
    <div class="wrap">
      <div class="wiki_detail_wrap">
        <div class="wiki_detail">
          <div class="wk_head_wrap">
            <div class="head_row1" style="width: 100%;padding: var(--px10) 0 var(--px20);">
              <span class="wk_t1">{{ pageData?.title ?? '' }}</span>
              <!-- <button class="wk_btn" type="button" onclick="history.go(-1);">返回</button> -->
            </div>
            <div class="head_row2" style="height: calc(3.2 * var(--px10));padding-bottom: var(--px20);">
              <span class="wk_t2">发布于：{{ moment.utc(pageData?.publishTime).local().format('YYYY-MM-DD HH:mm')
              }}&nbsp;&nbsp;浏览：{{ pageData?.readingCount ?? 0 }}
                <span v-if="pageData?.tag && !pageData?.type">&nbsp;&nbsp;分类：{{ pageData?.tag }} </span>
                <span v-if="pageData?.publisher">&nbsp;&nbsp;来源：{{ pageData?.publisher }} </span>
              </span>
              <!-- <div class="wk_t3">
                <span>分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;享:</span>
                <div style="display: flex;justify-content: space-between;width: 150px;margin-left: 40px;">
                  <img src="../assets/images/icon.png" width="35" height="30" alt="">
                  <img src="../assets/images/icon.png" width="35" height="30" alt="">
                  <img src="../assets/images/icon.png" width="35" height="30" alt="">
                </div>
              </div> -->
            </div>
          </div>
          <div class="wk_content" v-html="pageData?.richText">
          </div>
          <div style="display: flex;flex-direction: column;align-items: center; width: 100%;">
            <img style="max-width: 80%;margin-top: var(--px20);" v-for="(item, index) in pageData?.casePictureList"
              :key="index" :src="item.picUrl">
          </div>
          <slot name="wk_before_AD"></slot>
          <div class="wk_AD" style="position: relative;"><a href="#" @click.prevent="onClickAD" target="_blank"><img
                src="@/assets/images/wiki-bg-ad.jpg"></a>
          </div>
          <CompPagerPN v-if="!pageData?.noPager" :pre-t="pageData?.pre?.title" :next-t="pageData?.next?.title"
            :pre-url="pagerBaseUrl + pageData?.pre?.id" :next-url="pagerBaseUrl + pageData?.next?.id" />
        </div>
      </div>
      <div class="wiki_right">
        <slot name="wk_right_content">
          <!-- default -->
          <CompCardAbout />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import CompPagerPN from '@/components/CompPagerPN.vue'
import CompCardAbout from '@/components/CompCardAbout.vue'
import { inject } from 'vue'
import Emitter from '@/js/event-bus'

export default {
  name: 'WikiDetailTemplate',
  props: ['pageData', 'pagerBaseUrl'],
  components: { CompPagerPN, CompCardAbout },
  methods: {
    onClickAD() {
      Emitter.emit('onClickIM')
    }
  },
  setup() {
    const moment = inject('moment')
    return { moment }
  },
  data() {
    if (!this.pagerBaseUrl) { console.error('pagerBaseUrl unset') }
    return {}
  }
}
</script>

<style lang="scss" scoped>
.all_wrap {
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
  background: url("@/assets/images/bg-seperator-v.gif") calc(50% + 100px) top repeat-y;

  // background-color: #F8F8F8;
  --wiki-detail-template-sidebox-width: 320px
}

.wiki_right {
  float: right;

  // width: 320px;
  width: var(--wiki-detail-template-sidebox-width);
  overflow: hidden;
}

.wk_content {
  margin-top: 30px;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

.wk_AD {
  width: 100%;
  padding-top: 34px;
  padding-bottom: 30px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  color: #4c4c4c;

  img {
    width: 1024px;
  }
}

.wk_btn {
  float: right;
  width: 120px;
  height: 42px;
  font-size: 18px;
  color: #fff;
  background-color: var(--zs-default-color);
  border: none;
  border-radius: 21px;
}

.wiki_detail_wrap {
  float: left;
  width: 1040px;
  background-color: #fff;
}

.wiki_detail {
  padding: 10px 16px 38px 0;

  // width: 1311px;
  // height: calc(100% - 38px);
}

.wk_t1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 42px;
  color: #333;
}

.wk_t2 {
  font-size: 14px;
  line-height: 32px;
  color: #999;
}

.wk_t3 {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 10px;
  font-size: 14px;
  line-height: 40px;
  color: #999;
}

.wk_head_wrap {
  width: 100%;
  border-bottom: 1px solid #EAEAEA;
}
</style>
