<template>
  <Banner t1="案例展示" t2="行业深耕，客户至上" bg-cls="banner_bg_4" txt-cls="align_r" />
  <div class="product_main">
    <div class="wrap" style="display: flex;flex-direction: column;">
      <div class="ls_wrap">
        <ul>
          <li v-for="(item, index) in apiData.records" :key="index"><a :href="'/all_cases/case_detail/' + item.id" target="_blank">
              <div class="li_wrap">
                <ProductCard :title="item.title" :img-url="item.casePicture?.picUrl" btn-title="详情" :btn-url="'/all_cases/case_detail/' + item.id" />
              </div>
            </a></li>
        </ul>
      </div>
      <nav aria-label="all cases">
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a class="page-link" :href="getPrevUrl()" aria-label="Previous">
              <span aria-hidden="true">&lsaquo;</span>
            </a>
          </li>
          <li v-for="(item, index) in Array(apiData.pages)" :class="getPageItemClass(index)" :key="index">
            <a class="page-link" :href="getPageItemUrl(index)">{{ index + 1 }} </a>
          </li>
          <li class="page-item">
            <a class="page-link" :href="getNextUrl()" aria-label="Next">
              <span aria-hidden="true">&rsaquo;</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import ProductCard from '@/components/CompCardShowCase.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'ShowCases',
  computed: {
    routeName() {
      return this.$route.name
    },
    curPage() {
      const curPage = parseInt(this.$route.params.id) || 0
      return Math.max(curPage, 1)
    }
  },
  components: {
    Banner,
    ProductCard,
  },
  methods: {
    getPageItemClass(index) {
      if (index + 1 === this.curPage) {
        return 'page-item active'
      }
      return 'page-item'
    },
    getPageItemUrl(index) {
      return '/' + this.routeName + '/' + (index + 1)
    },
    getPrevUrl() {
      return '/' + this.routeName + '/' + Math.max(1, this.apiData.current - 1)
    },
    getNextUrl() {
      return '/' + this.routeName + '/' + Math.min(this.apiData.pages, this.apiData.current + 1)
    }
  },
  setup() {
    useMeta({
      title: '案例展示',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  created() {
    this.$store.$api.postJson('trainCaseList', { curPage: this.curPage, pageSize: 12 }).then(
      data => {
        // //console.log('### data => \n', data)
        this.apiData = data
      })
  },
  data() {
    return { apiData: { pages: 1, size: 12, current: 1, records: [] } }
  },
  mounted() {
    // //console.log(this.$route.path, ', => mounted, ', this.$route.params, this.curPage)
  }
}
</script>

<style lang="scss" scoped>
.product_main {
  position: relative;
  width: 100%;
  max-width: 2560px;
  height: 1481px;
  overflow: hidden;
  background: url("../assets/images/showcase-bg-2.jpg") center/cover no-repeat;
}

.ls_wrap {
  width: 1400px;
  padding-bottom: 22px;
  margin-top: 48px;
  clear: both;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      width: calc(328px + 14px);
      height: calc(409px + 24px);
    }

    li a {
      text-decoration: none;
      text-transform: none;
    }
  }

}

.li_wrap {
  position: relative;
  width: 328px;
  height: 409px;
  background-color: #f5f5f5;
}
</style>
