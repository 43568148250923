<template>
  <div ref="addr" class="addr">
    <div class="addr_ic" :style="{ background: 'url(' + iconUrl + ') center center no-repeat' }"></div>
    <div class="addr_tt">
      <div class="addr_t1">{{ t1 ?? "" }}</div>
      <div ref="addr_t2" class="addr_t2">{{ t2 ?? "" }}</div>
    </div>
    <img id="qrcode" :src="imgUrl" v-if="imgUrl" />
  </div>
</template>
<script>
export default {
  name: 'comp-contact-us-addr',
  props: ['iconUrl', 't1', 't2', 'imgUrl'],
  methods: {
    isEllipsisActive(e) {
      return (e.offsetWidth < e.scrollWidth)
    },
    fixAddrOverflow() {
      const el = this.$refs.addr_t2
      const overflow = this.isEllipsisActive(el)
      if (overflow) {
        el.style.whiteSpace = 'unset'
        el.style.overflow = 'inherit'
        this.$refs.addr.style.height = '90px'
      }
    }
  },
  mounted() {
    this.fixAddrOverflow()
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.addr {
  position: relative;
  height: 78px;
  margin: 0 40px 0 18px;

  &:not(:last-child) {
    border-bottom: 1px solid #E7E7E7;
  }

  img#qrcode {
    position: absolute;
    top: 8px;
    right: -28px;
    width: 125px;
    height: 125px;

    // content: url("../assets/images/qrcode.png");
  }

  >.addr_ic {
    position: absolute;
    top: 17px;
    width: 34px;
    height: 34px;
  }

  >.addr_tt {
    position: absolute;
    top: 16px;
    left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 320px;
    font-size: 18px;
    line-height: 1.333;

    >.addr_t1 {
      color: #333;
    }

    >.addr_t2 {
      flex: 0 0 1.333em;
      max-width: 320px;
      overflow: hidden;
      color: #999;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
