import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import api from '~api'
import moment from 'moment'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/scss/style.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager())
store.$api = store.state.$api = api
app.provide('moment', moment)
app.provide('g_companyNameL', '上海颜回纺织咨询服务有限公司')
app.config.globalProperties.companyNameS = '颜回'
app.config.globalProperties.companyNameM = '上海颜回'
app.config.globalProperties.companyNameL = '上海颜回纺织咨询服务有限公司'
app.config.globalProperties.companyICP = '沪ICP备2020027344号-2'
app.config.globalProperties.companyBmapLL = { long: 121.76354237592375, lat: 31.529152357794434 }
app.mount('#app')
