<template>
  <div class="main-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'main-wrapper',
  data() {
    return {}
  }
}
</script>
