<template>
  <div class="sbox_detail">
    <div class="sbox_detail_t"> {{ tt ?? '' }}</div>
    <div class="sbox_detail_ls">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <div class="sbox_detail_li_t1"><a :href="item.url"
              target="_blank">{{ item.title }}</a></div>
          <div class="sbox_detail_li_t2">{{ item.description }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-sidebox-list-detail',
  props: ['list', 'tt'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.sbox_detail {
  width: 270px;
  padding: 15px 25px 10px;
  overflow: hidden;
  background-color: #f8f8f8;
}

.sbox_detail_t {
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 50px;
  color: #1a1a1a;
  text-indent: 18px;
  @include left-indicator(8px, 35px, var(--zs-default-color), 8px);
}

.sbox_detail_ls {
  width: 100%;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      width: 100%;
      margin-bottom: 15px;
      overflow: hidden;
      border-bottom: 1px solid #e0e0e0;

      &:last-child {
        border: none;
      }
    }
  }
}

.sbox_detail_li_t1 {
  width: 100%;
  padding-bottom: 12px;
  overflow: hidden;
  line-height: 20px;

  a {
    font-size: 14px;
    color: #1a1a1a;
    text-decoration: none;

    &:hover {
      font-weight: bold;
    }
  }
}

.sbox_detail_li_t2 {
  width: 100%;
  padding-bottom: 18px;
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;
  color: #999;
}
</style>
