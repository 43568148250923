<template>
  <div class="sidebar">
    <ul>
      <li style="position:relative;">
        <div class="sd_item si_1" @click="onClickIM" @onClickIM="onClickIM"></div>
      </li>
      <li id="si_2" style="position:relative;width: 100%;" @mouseover="onEnterItem" @mouseout="onLeaveItem">
        <div class="sd_item si_2"></div>
        <transition name="slide-fade">
          <div class="sd_tel sd_hover" v-show="showTel">
            <div class="sd_tel_t">拨打电话咨询</div>
            <div class="sd_tel_num">{{ apiData?.formattedTel ?? '' }}</div>
          </div>
        </transition>
      </li>
      <li id="si_3" style="position:relative;" @mouseover="onEnterItem" @mouseout="onLeaveItem">
        <div class="sd_item si_3 ck_ico_3"></div>
        <transition name="slide-fade">
          <div class="sd_wx sd_hover" v-show="showWechat">
            <div class="sd_wx_t">添加微信咨询</div>
            <img class="sd_wx_img" style="pointer-events: none;" :src="apiData?.wxUrl" />
          </div>
        </transition>
      </li>
      <li>
        <div onclick="window.scrollTo({top: 0, behavior: 'smooth'})" class="sd_item si_4" id="back-to-top"
          style="display: block;"></div>
      </li>
    </ul>
  </div>
</template>

<script>
import Emitter from '@/js/event-bus'

export default {
  name: 'comp-sidebar',
  props: ['apiData'],
  methods: {
    onEnterItem(item) {
      const id = item.toElement.id
      const cls = item.toElement.className
      // //console.log('>> ', item.toElement)
      if (cls.includes('si_3') || cls.includes('sd_wx')) {
        this.showWechat = true
      } else if (id.includes('si_2') || cls.includes('si_2') || cls.includes('sd_tel')) {
        this.showTel = true
      }
    },
    onLeaveItem(item) {
      // //console.log(' << ', item.srcElement)
      const cls = item.srcElement.className
      const id = item.srcElement.id
      if (cls.includes('si_3') || cls.includes('sd_wx')) {
        this.showWechat = false
      } else if (id.includes('si_2') || cls.includes('si_2') || cls.includes('sd_tel')) {
        this.showTel = false
      }
    },
    hijackAffIcon(bridge) {
      const targetNode = bridge
      const config = { attributes: false, childList: true, subtree: false }
      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const iconWrap = mutation.addedNodes[0]
            if (iconWrap?.id === 'nb_icon_wrap') {
              // //console.log(iconWrap)
              iconWrap.style.top = 'var(--bd-aff-top)'
              iconWrap.style.pointerEvents = 'none'
              const marquee = document.getElementById('issueMarquee')
              marquee.style.pointerEvents = 'auto'
              this.onClickIM = () => {
                iconWrap.click()
              }
              observer.disconnect()
            }
          }
        }
      }

      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
    },
    hijackAffBridge() {
      const targetNode = document.body || document.getElementsByTagName('body')[0]
      const config = { attributes: false, childList: true, subtree: false }

      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            if (mutation.addedNodes[0]?.id === 'newBridge') {
              const ins = mutation.addedNodes[0]
              // //console.log('### childList changed: ', ins)
              this.$nextTick(() => {
                this.hijackAffIcon(ins)
              })
              observer.disconnect()
            }
          } else if (mutation.type === 'attributes') {
            // //console.log(`### attribute modified: ${mutation.attributeName}`)
          }
        }
      }

      const observer = new MutationObserver(callback)
      observer.observe(targetNode, config)
      // fallback
      setTimeout(() => {
        observer.disconnect()
      }, 30000)
    }
  },
  mounted() {
    this.onClickIM = () => {
      window.open(window.affOpenUrl, '_blank')
    }
    this.hijackAffBridge()
    Emitter.on('onClickIM', e => {
      this.onClickIM()
    })
  },
  data() {
    return {
      showWechat: false,
      showTel: false,
      onClickIM: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
/* Vue JS CSS Components */
.slide-fade-enter-active,
.slide-fade-enter {
  transition: opacity 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.slide-fade-leave-to,
.slide-fade-enter-from {
  opacity: 0;

  /* transform: translateY(-50px); */
}

.sd_hover {
  flex-shrink: 1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  @include flex-col(center, center)
}

.sd_tel {
  position: absolute;
  top: -30px;
  right: 57px;
  min-width: 151px;
  height: 80px;
  box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);
}

.sd_wx {
  position: absolute;
  top: -70px;
  right: 57px;
  min-width: 151px;
  box-shadow: 0 0 0.625rem 0 rgb(0 0 0 / 10%);

  // height: 192px;
}

.sd_wx_t {
  margin: 20px 0 10px;
  color: #999;
  @include text-single-line-min(14px);
}

.sd_wx_img {
  width: 125px;
  height: 125px;
}

.sd_tel_t {
  height: 25px;
  font-size: 14px;
  line-height: 25px;
  color: #999;
}

.sd_tel_num {
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
  color: #666;
  white-space: nowrap;
}

.sidebar {
  position: fixed;
  top: 270px;
  right: 0;
  z-index: 100;
  width: 60px;
  height: 228px;
  background-color: #fff;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.sidebar ul {
  width: 34px;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  @include flex-col(center, space-evenly);
}

.sidebar ul li {
  position: relative;
  flex-grow: 1;
  width: 34px;
  height: 56px;

  &:not(:first-child) {
    border-top: 1px solid #ccc;
  }

  .sd_item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--zs-default-color); // fallback
    background: linear-gradient(to right, #ed213a, #93291e);

    // background: linear-gradient(to bottom right, #FFDB01, #0E197D);
  }

  .sd_item:hover {
    @extend %anime-icon-zoom;
  }

  .si_1 {
    mask: url("../assets/images/ic-online.png") center/contain no-repeat;
  }

  .si_2 {
    background: linear-gradient(to right, #dd1818, #333);
    mask: url("../assets/images/ic-telephone.svg") center/contain no-repeat;
  }

  .si_3 {
    mask: url("../assets/images/ic-wechat.svg") center/contain no-repeat;
  }

  .si_4 {
    background: linear-gradient(to right, #c31432, #240b36);
    mask: url("../assets/images/ic-gotop.svg") center/contain no-repeat;
  }
}
</style>
