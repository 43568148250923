<template>
  <div class="nav_footer">
    <div class="wrap">
      <div class="footer_container">
        <div class="ft_content">
          <div class="menu">
            <ul class="row g-0 flex-nowrap">
              <li class="col-md-6">
                <div class="menu_li">
                <div class="menu_T">推荐服务</div>
                  <dl>
                    <dt v-for="(item, index) in menuData" :key="index">
                      <a :href="'/all_products/product_detail/' + item.id ?? ''" target="_blank">{{ item.title }}</a>
                    </dt>
                  </dl>
                </div>
              </li>
              <li class="col-md-5">
                <div class="menu_T">关于{{ this.companyNameS }}</div>
                <div class="menu_li">
                  <dl>
                    <dt><a href="/about/" target="_blank">公司简介</a></dt>
                    <dt><a href="/all_cases/" target="_blank">案例展示</a></dt>
                    <dt><a href="/why_choose_us/" target="_blank">服务优势</a></dt>
                    <dt><a href="/contact_us/" target="_blank">联系我们</a></dt>
                  </dl>
                </div>
              </li>
              <li class="col-md-2">
                <div class="menu_T">新闻资讯</div>
                <div class="menu_li">
                  <dl>
                    <dt><a href="/wiki/1" target="_blank">标准介绍</a></dt>
                    <dt><a href="/wiki/2" target="_blank">行业动态</a></dt>
                    <dt><a href="/wiki/3" target="_blank">政策标准</a></dt>
                    <dt><a href="/wiki/4" target="_blank">常见问题</a></dt>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
          <div class="ft_ctct">
            <div class="ft_ctct_A">全国统一咨询热线（7X24）</div>
            <div class="ft_ctct_B">{{ apiData.formattedTel }}</div>
            <div class="ft_ctct_C">邮箱：{{ apiData.mail }}</div>
            <div class="ft_ctct_D">
              <div class="c_about_im"><a href="#" target="_blank" @click.prevent="onClickIM"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                    class="bi bi-chat-left-dots" viewBox="0 0 16 16">
                    <path
                      d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path
                      d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>&nbsp;&nbsp;&nbsp;在线咨询</a></div>
            </div>
          </div>
          <div class="contact_box">
            <!-- <img src="../assets/images/logo-bottom.svg" class="ft-logo" /> -->
            <img class="ct_box_img" :src="apiData.wxUrl" />
          </div>
        </div>

        <div class="ft_copyrights">
          Copyright © 2023&nbsp;&nbsp;&nbsp;{{ this.companyNameL }}&nbsp;版权所有&nbsp;&nbsp;&nbsp; <a href="http://beian.miit.gov.cn/" target="_blank" class="text-white" >{{ this.companyICP }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Emitter from '@/js/event-bus'

export default {
  name: 'comp-footer',
  props: ['apiData', 'menuData'],
  methods: {
    onClickIM() {
      Emitter.emit('onClickIM')
    }
  },
  created() {
    this.$store.dispatch('updateCommonInfo')
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: relative;
  width: 600px;

  // height: 350px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
}

.menu_T {
  width: 100%;
  height: 68px;
  font-size: 18px;
  line-height: 68px;
  color: #fff;
}

.menu_li {
  width: 100%;
  overflow: hidden;
}

.menu_li dl {
  padding: 0;
  margin: 0;
  list-style: none;

  dt {
    float: left;
    width: 100%;
    height: 32px;
    line-height: 32px;

    a {
      font-size: 14px;
      color: #888;
      text-decoration: none;
      white-space: nowrap;
      transition: all 0.1s linear;

      &:hover {
        color: #fff;
      }
    }
  }
}

.ft_ctct {
  position: relative;
  width: 251px;
  padding-left: 200px;
}

.ft_ctct_A {
  width: 100%;
  height: 67px;
  font-size: 18px;
  font-weight: bold;
  line-height: 67px;
  color: #fff;
}

.ft_ctct_B {
  width: 100%;
  height: 42px;
  font-family: Arial, Helvetica, sans-serif, var(--font-family-common);
  font-size: 36px;
  line-height: 42px;
  color: #ffc80a;
  color: var(--zs-default-color-bgdark)
}

.ft_ctct_C {
  width: 100%;
  height: 42px;
  font-size: 14px;
  line-height: 42px;
  color: #7f7f7f;
}

.ft_ctct_D {
  width: 100%;
  padding-top: 9px;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.nav_footer {
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-color: #111;
}

.footer_container {
  position: relative;
  width: 1400px;
  height: 462px;
}

.contact_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 24px;
  text-align: right;

  img[src=""],
  img:not([src]) {
    opacity: 0;
  }

  img[src="*"] {
    opacity: 1;
  }

  .ft-logo {
    display: flex;
    width: 236px;
    height: 78px;
    margin: 0 auto 20px;
    filter: drop-shadow(0 0 0.5rem #fff);

    // filter: brightness(10);
  }

  .ct_box_img {
    display: inline-block;
    align-items: flex-end;
    width: 155px;
    height: 155px;
    margin: 0 auto;
    border: 0;
    outline: none;
  }
}

.c_about_im {
  width: 100%;
  height: 46px;
  margin-top: 18px;
  text-align: center;

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    display: block;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    line-height: 46px;
    color: #fff;

    // background: url("@/assets/images/ico_chat.png") 76px 14px no-repeat;
    border: 1px solid #fff;
    border-radius: 4px;
  }
}

.ft_content {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 350px;
  margin-top: 44px;
}

.ft_copyrights {
  height: 14px;
  padding-top: 22px;
  font-size: 14px;
  color: #fff;
}
</style>
