import { createStore } from 'vuex'
import sharedData from './sharedInfo'

const store = createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sharedData
  }
})

export default store
