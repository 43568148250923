<template>
  <ul v-if="apiData?.length" class="dropdown" ref="dropdown" :class="{ 'isOpen': isOpen }">
    <li :id="index + '_menu_li'" v-for="(item, index) in apiData" :key="index" @mouseover="onHoverMenu"
      @mouseleave="onLeaveMenu" :class="{ haschild: item.childList && item.childList.length }">
      <div>
        <a href="" :id="index + '_menu_a'" @click.prevent="">
          {{ item.title }}
          <div v-if="item.childList && item.childList.length"
            :class="{ dropdown: true, submenu_wrap: true, isOpen: getSubMenuOpen(index) }"
            :style="{ width: 200 * Math.min(item.childList.length, 3) + 'px' }">
            <ul class="submenu">
              <li v-for="(subItem, subIndex) in item.childList" :key="subIndex">
                <a :href="'/all_products/product_detail/' + subItem.id" @click="onClickLink">{{
                  subItem.title }}</a>
              </li>
            </ul>
          </div>
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'comp-dropdown-menu',
  props: ['isOpen', 'apiData'],
  watch: {
    isOpen(open, prev) {
      if (!open) this.subMenuIndex = -1
    }
  },
  methods: {
    getSubMenuOpen(index) {
      return ~~index === ~~this.subMenuIndex
    },
    onHoverMenu(el) {
      // // //console.log('### onHoverMenu', el.toElement)
      if (el.toElement.id.includes('_menu_a')) {
        this.subMenuIndex = parseInt(el.target.id)
      }
    },
    onLeaveMenu(el) {
      // // //console.log('### onLeaveMenu', el.srcElement, typeof el.srcElement.id)
    },
    onClickLink(event) {
      event.stopImmediatePropagation()
      event.stopPropagation()
    }
  },
  mounted() {
  },
  data() {
    return { subMenuIndex: -1 }
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding: 0;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 15%);

  &.submenu_wrap {
    top: 0;
    left: 100%;
    width: 600px;
    height: auto;
  }
}

.dropdown>li {
  position: relative;

  // padding-right: 100px;
  // margin-right: -100px;
  background-color: unset;

  a {
    display: block;
    padding: 10px;
    line-height: 2;
    color: #333;
    text-decoration: none;
  }

  >div {
    min-width: 150px;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #eee;

  }

  &.haschild {
    background: url("../assets/images/arrow-right-s.png") 134px center no-repeat;
  }

  &:hover {
    font-weight: bold;
    background-color: #f5f5f5;
    border-left: 3px solid var(--zs-default-color);
  }

  .submenu {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    >li {
      position: relative;
      flex: 0 1 200px;
      max-width: 200px;
      font-weight: normal;

      &:hover {
        font-weight: bold;
        color: #000;

        &::after {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 1;
          width: 80%;
          height: 2px;
          margin-top: -2px;
          content: "";

          // background-color: var(--zs-default-color);
          background-image: linear-gradient(to right top, #e11313, #e11313aa, #e1131311);
        }
      }
    }
  }
}

.isOpen {
  display: block;
}
</style>
