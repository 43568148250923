<template>
  <div class="toast-container position-fixed top-0 end-0 p-3">
    <div ref="liveToast" class="toast align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ title }}
        </div>
        <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'

export default {
  name: 'comp-toast',
  methods: {
    show(title, success) {
      this.title = title
      if (success) {
        this.$refs.liveToast.style['background-color'] = 'var(--bs-toast-bg-success)'
      } else {
        this.$refs.liveToast.style['background-color'] = 'var(--bs-toast-bg)'
      }
      const liveToast = this.$refs.liveToast
      const toast = new Toast(liveToast)
      toast.show()
    },
  },
  data() {
    return { title: '' }
  }
}
</script>
