<template>
  <div class="all_products">
    <div class="wrap">
      <div class="zs_main_T hot_pd" style="box-sizing: content-box;">
        <span>热门产品认证</span>
        <label class="more more_large" id="hot-pd-more"><router-link to="/all_products/" exact>更多
            &gt;</router-link></label>
      </div>
      <div class="zs_hot_wrap">
        <div class="zs_hot_box text_between">
          <div :class="'zs_hot_card bg_' + (index + 1)" v-for="(item, index) in hotProducts" :key="index">
            <div class="card-img-overlay" />
            <div class="z-1000">
              <div class="hot_card_T text-truncate">{{ item.t }}</div>
              <div class="hot_card_desc">{{ item.desc }}</div>
              <div class="hot_card_more"><a :href="item.url" target="_blank">查看详情+</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'comp-home-hot-products',
  props: ['apiData'],
  data() {
    return {
      hotProducts: computed(() => [
        {
          t: 'GRS回收认证',
          desc: '最初由荷兰管制联盟制定，于2008年1月开始实施，GRS认证是一项针对废旧纺织品回收后，再生纤维使用所建立的第三方认证标准。',
          url: 'all_products/product_detail/' + this.apiData[0],
        },
        {
          t: 'GOTS全球有机纺织品认证',
          desc: '该标准由国际天然纺织品协会（IVN）、日本有机棉协会（JOCA），美国有机贸易协会（OTA）和英国土壤协会（SA）组成的GOTS国际工作组IWG共同制定和发布。第一个版本于2006年9月正式颁布和实施。2020年3月，发布了GOTS 5.0及其实施手册，该版本将于2021年3月正式实施。',
          url: 'all_products/product_detail/' + this.apiData[1],
        },
        {
          t: '森林认证',
          desc: '森林认证，又叫做木材认证或统称为认证，是一种运用市场机制来促进森林可持续经营，实现生态、社会和经济目标的工具。',
          url: 'all_products/product_detail/' + this.apiData[2],
        },
        {
          t: 'BSCI认证',
          desc: '通过BSCI一个认证可以应付不同的客人，减少国外客户对供应商的第二方审核，节省费用；建立国际公信力，提高企业形象；使消费者对产品建立正面情感；稳固与采购商的合作，并拓宽新市场。',
          url: 'all_products/product_detail/' + this.apiData[3],
        },
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
#hot-pd-more {
  margin-top: -8px;
}

.all_products {
  position: relative;
  width: 100%;
  max-width: 2560px;
  height: 806px;
  overflow: hidden;
  background: url("../assets/images/main-bg-1.jpg") center/cover no-repeat;
}

.zs_hot_wrap {
  width: 1400px;
  height: 510px;
}

.zs_hot_box {
  width: 1403px;
  height: 510px;

  div:not(:first-child) {
    margin-left: 1px;
  }

  .zs_hot_card {
    position: relative;
    float: left;
    width: 350px;
    height: 510px;

    &.bg_1 {
      background: url("../assets/images/main-bg-1-1.jpg") no-repeat;
    }

    &.bg_2 {
      background: url("../assets/images/main-bg-1-2.jpg") no-repeat;
    }

    &.bg_3 {
      background: url("../assets/images/main-bg-1-3.jpg") no-repeat;
    }

    &.bg_4 {
      background: url("../assets/images/main-bg-1-4.jpg") no-repeat;
    }

    .card_mask {
      z-index: 0;
      background-color: rgb(56 56 56 / 80%);
    }

    &:hover {
      .card_mask {
        background-color: rgb(56 56 56 / 60%);
      }
    }

    .z-1000 {
      z-index: 1000;
    }

    .hot_card_T {
      position: absolute;
      top: 12px;
      left: 27px;
      width: 292px;
      height: 3em;
      font-size: 22px;
      line-height: 3;
      color: #fff;
      border-bottom: 1px solid #fff;
    }

    .hot_card_desc {
      position: absolute;
      top: 100px;
      left: 29px;
      width: 300px;
      height: 120px;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
    }

    .hot_card_more {
      position: absolute;
      bottom: 53px;
      left: 96px;
      width: 162px;
      height: 44px;

      a {
        display: block;
        width: 158px;
        height: 2.5em;
        font-size: 16px;
        font-weight: bold;
        line-height: 2.5;
        color: #fff;
        text-align: center;
        border: 2px solid #fff;

        &:hover {
          color: #1a1a1a;
          background-color: #fff;
          border-color: #fff;
        }
      }
    }
  }
}

.zs_main_T {
  &.hot_pd {
    padding-top: 94px;
    padding-bottom: 47px;
  }

  label {
    /* display: inline; */
    float: right;
  }
}
</style>
