<template>
  <div id="sidebox_float">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'comp-sidebox-float-wrap',
  data() {
    return {}
  },
  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect()
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
        bottom: rect.top + window.scrollY + rect.height
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      let l = true
      const timer = null
      const menu = document.getElementById('sidebox_float')
      const footer = document.getElementsByClassName('nav_footer')[0]
      const top = this.getOffset(menu).top
      let takeSpaceV = menu.getBoundingClientRect().height + 120
      // const that = this

      function doFix() {
        if (typeof timer === 'number') {
          clearTimeout(timer)
        }
        // timer = setTimeout(function () {
        const scrollTop = window.scrollY
        const dis = parseInt(top) - parseInt(scrollTop)
        if (l && dis <= 0) {
          menu.className = 'fixed_right'
          l = false
        }
        if (!l && dis > 0) {
          menu.className = ''
          l = true
        }
        const updatedH = menu.getBoundingClientRect().height + 120
        if (updatedH > takeSpaceV) takeSpaceV = updatedH
        const spaceAllowV = window.innerHeight - takeSpaceV // 可能是 负
        // // //console.log(1)
        // //console.log('xxx', window.innerHeight, takeSpaceV, 'menu.top', that.getOffset(menu).top)
        // //console.log(footer.getBoundingClientRect().top, spaceAllowV)
        if (footer.getBoundingClientRect().top <= Math.min(window.innerHeight, window.innerHeight - spaceAllowV) + 10) {
          menu.style.display = 'none'
        } else {
          menu.style.display = 'block'
        }
        // }, 20)
      }

      window.onscroll = doFix
    })
  },
}
</script>

<style lang="scss" scoped>
.fixed_right {
  position: fixed;
  _position: absolute;
  top: 120px;
  z-index: 99;
  width: 320px;
}
</style>
