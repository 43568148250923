<template>
  <div class="wiki_card">
    <div class="wk_date">
      <span class="wk_d1">{{ d1 }}</span>
      <span class="wk_d2">{{ d2 }}</span>
    </div>
    <div class="wk_tt">
      <div class="wk_t1">{{ t1 }}</div>
      <div class="wk_t2">{{ t2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-wiki-card',
  props: ['t1', 't2', 'd1', 'd2'],
  data() {
    return {}
  }
}
</script>

<style scoped>
.wiki_card {
  position: relative;
  display: flex;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgb(4 0 0 / 10%);
}

.wk_date {
  position: relative;
  top: 23px;
  width: 134px;
  height: 140px;
  margin-right: 37px;
  margin-left: 20px;
  text-align: center;
  background-color: rgb(var(--zs-default-color-rgb) / 50%)
}

.wk_d1 {
  position: relative;
  top: 35px;
  font-size: 60px;
  line-height: 48px;
  color: white;
}

.wk_d2 {
  position: absolute;
  top: 95px;
  left: 35px;
  font-size: 18px;
  line-height: 1;
  color: white;
}

.wk_tt {
  position: relative;
  top: 23px;
  width: 465px;
  height: 140px;
}

.wk_t1 {
position: absolute;
top: 5px;
font-size: 18px;
line-height: 1;
color: var(--zs-default-color);
}

.wk_t2 {
position: absolute;
top: 82px;
font-size: 14px;
color: #666;
}
</style>
