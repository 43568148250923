<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | ${ this.companyNameM } | 管理咨询 | 能力培训 | 企业认证` : `${ this.companyNameM }` }}</template>
  </metainfo>
  <Navigation :backend="backend"></Navigation>
  <MainWrap>
    <router-view :key="this.$route.path" />
    <Footer :api-data="this.$store.state.sharedData.companyInfo" :menu-data="this.$store.state.sharedData.footerProjectList" />
    <Sidebar :api-data="this.$store.state.sharedData.companyInfo" />
  </MainWrap>
</template>

<script>
import Navigation from '@/components/CompNavigation.vue'
import Footer from '@/components/CompFooter.vue'
import Sidebar from '@/components/CompSidebar.vue'
import MainWrap from '@/views/MainWrap.vue'
import { useMeta } from 'vue-meta'
import { inject } from 'vue'

export default {
  name: 'app',
  props: ['backend'],
  components: {
    Navigation,
    Footer,
    Sidebar,
    MainWrap
  },
  setup() {
    const companyNameL = inject('g_companyNameL')
    useMeta({
      title: `${companyNameL}`,
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  data() {
    // //console.log('### store.state.sharedData => \n', this.$store.state.sharedData.companyInfo)
    return {}
  }
}

</script>
