<template>
  <div :class="'banner_common ' + bgCls">
    <div :class="'banner_t ' + txtCls">
      <div class="banner_t1">{{ t1 }}</div>
      <div class="banner_t2">{{ t2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-banner',
  props: ['bgCls', 't1', 't2', 'txtCls'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.banner_common {
  width: 100%;
  height: 388px;
  overflow: hidden;
}

.banner_bg_1 {
  background: url("../assets/images/products-bg-1.jpg") center top no-repeat;
}

.banner_bg_2 {
  background: url("../assets/images/wcu-bg-1.jpg") center top no-repeat;
}

.banner_bg_3 {
  background: url("../assets/images/about-bg-1.jpg") center top no-repeat;
}

.banner_bg_4 {
  background: url("../assets/images/showcase-bg-1.jpg") center top no-repeat;
}

.banner_bg_5 {
  background: url("../assets/images/wiki-bg-1.jpg") center top no-repeat;
}

.banner_t {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: -25px;
  color: #fff;

  >.banner_t1 {
    font-size: 36px;
    font-weight: bold;
  }

  >.banner_t2 {
    font-size: 24px;
  }

  &.align_r {
    align-items: flex-end;
    margin-right: calc(50vw - 960px + 284px);
  }

  &.align_l {
    align-items: flex-start;
    margin-left: calc(50vw - 960px + 250px);

    &.color_rb {
      >.banner_t1 {
        color: var(--zs-default-color);
      }

      >.banner_t2 {
        color: #333;
      }
    }
  }
}
</style>
