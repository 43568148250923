<template>
  <Banner t1="认证咨询百科" t2="行业深耕，客户至上" bg-cls="banner_bg_5" txt-cls="align_l" />
  <nav class="zs_breadcrumb" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/">首页</a></li>
      <li class="breadcrumb-item"><a href="/wiki">认证咨询百科</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ pageData?.title }}</li>
    </ol>
  </nav>
  <WikiDetailTemplate :page-data="pageData" pager-base-url="/wiki/wiki_detail/">
    <template #wk_right_content>
      <CompSideboxListTitle style="padding-bottom: var(--px20);" tt="热点资讯" :list="hotWikiList" />
      <CompSideboxListIcon tt="服务项目" :list="projectList" />
    </template>
  </WikiDetailTemplate>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import WikiDetailTemplate from './WikiDetailTemplate.vue'
import CompSideboxListTitle from '@/components/CompSideboxListTitle.vue'
import CompSideboxListIcon from '@/components/CompSideboxListIcon.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'WikiDetailView',
  components: { Banner, WikiDetailTemplate, CompSideboxListTitle, CompSideboxListIcon },
  computed: {
    routeName() {
      return this.$route.name
    },
    category() {
      return this.$route.params.type ?? '1'
    },
    curId() {
      return this.$route.params.id ?? '1'
    },
  },
  methods: {
    getTabItemClass(el) {
      return ~~this.category === ~~el ? 'active' : ''
    },
  },
  setup() {
    useMeta({
      title: '认证咨询百科详情',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  created() {
    this.$store.$api.postJson('getWikiDetail/' + this.curId, {}).then((data) => {
      // //console.log('### data =>\n', data)
      this.pageData = data.wiki
      this.pageData.pre = data.preWiki
      if (~~this.pageData.pre.id < 0) this.pageData.pre.id = this.pageData.id
      this.pageData.next = data.nextWiki
      if (~~this.pageData.next.id < 0) this.pageData.next.id = this.pageData.id
      this.projectList = data.projectList
      if (this.projectList.length > 8) this.projectList = this.projectList.slice(0, 8)
      this.projectList.forEach(item => {
        item.title = item.name1
        item.url = '/all_products/product_detail/' + item.id
      })
      this.hotWikiList = data.hotWikiList
      if (this.hotWikiList.length > 6) this.hotWikiList = this.hotWikiList.slice(0, 6)
      this.hotWikiList.forEach(item => {
        item.url = '/wiki/wiki_detail/' + item.id
      })
    })
  },
  data() {
    return {
      pageData: {},
      projectList: [],
      hotWikiList: []
    }
  }
}
</script>
