<template>
  <div class="wiki_rb">
    <div class="wiki_rb_T"><span>{{ tt }}</span></div>
    <div class="wiki_rb_ls">
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <a :href="item.url" :title="item.title ?? ''" target="_blank">• {{ item.title ?? '' }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-sidebox-list-title',
  props: ['list', 'tt'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.wiki_rb {
  width: 260px;
  padding: 10px 30px 0;
  margin-top: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
}

.wiki_rb_T {
  position: relative;
  width: 260px;
  height: 62px;
  text-indent: 18px;
  border-bottom: 1px solid #dbdbdb;
  @include left-indicator(8px, 35px, var(--zs-default-color), 14px);

  span {
    display: inline;
    float: left;
    width: 180px;
    height: 62px;
    font-size: 22px;
    line-height: 62px;
    color: #1a1a1a;
  }

  label {
    float: right;
    width: 80px;
    height: 62px;
    line-height: 62px;
    text-align: right;

    a {
      font-size: 14px;
      color: #666;
      text-decoration: none;
    }
  }
}

.wiki_rb_ls {
  width: 260px;
  margin: 0 auto;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      width: 260px;
      height: 34px;
      overflow: hidden;
      line-height: 34px;
      text-overflow: ellipsis;
      white-space: nowrap;

      :last-child {
        border: none;
      }

      >a {
        font-size: 14px;
        color: #1a1a1a;
        text-decoration: none;

        &:hover {
          font-weight: bold;

          // color: var(--zs-default-color);
        }
      }
    }
  }
}
</style>
