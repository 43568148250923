<template>
  <div class="about_us">
    <div class="wrap">
      <div class="zs_about">
        <div class="about_A">关于我们</div>
        <div class="about_B">行业深耕，客户至上</div>
        <div class="about_C text_between">至善咨询下辖 <b>苏州至善、苏州颜回、广州至善、广州尚行、上海颜回、青岛颜回、深圳尚行</b> 等 7
          家子公司，公司专注于为纺织行业提供认证服务。公司拥有一批深耕于纺织行业资深人士，对纺织行业的生产工艺流程、企业的管理、品质的控制流程等有深刻的了解和独到的见解，积累了丰富的行业经验，曾服务过长三角、珠三角等多家知名企业，如浙江乐高实业、苏州盛虹、浙江永通、三阳纺织、深圳华丝、宜兴乐祺、泰达纺织、山东宏诚家纺、安徽鸿美达、无锡方晟、兰西天纤坊、宜兴江丰纺织、绍兴福莱斯、深圳华丝、上海卓衣公司等，并赢得了客户的广泛好评。
          “客户至上、至善至美” 是我们的服务宗旨。 “专业、真诚、至善、至美” 是我们永远的追求，至善咨询专注于纺织行业，竭诚为全国乃至东南亚客户提供优质的服务！
        </div>
        <div>
          <label class="more more_large">
            <router-link to="/about/" exact>更多 &gt;</router-link>
          </label>
        </div>
        <div class="about_D">
          <img src="../assets/images/main-bg-2-1b.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'comp-home-about-us',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.about_us {
  position: relative;
  width: 100%;
  max-width: 2560px;
  height: 816px;
  overflow: hidden;
  background: url("../assets/images/main-bg-2.jpg") center/cover no-repeat;
}

.zs_about {
  position: relative;
  width: 1400px;
  height: 640px;

  .about_A {
    position: absolute;
    top: 116px;
    left: 0;
    width: 324px;
    font-weight: bold;
    color: var(--zs-t1-color-0);
    @include text-single-line-min(48px);
  }

  .about_B {
    position: absolute;
    top: 173px;
    left: 0;
    width: 324px;
    height: 79px;
    font-size: 30px;
    font-weight: bold;
    line-height: 79px;
    color: var(--zs-t2-color);
    @include bottom-indicator(34px, 6px, var(--zs-default-color), -6px)
  }

  .about_C {
    position: absolute;
    top: 290px;
    left: 0;
    width: 696px;
    height: 335px;
    font-size: 20px;
    line-height: 1.7;
    color: var(--zs-txt-color);
  }

  .about_D {
    position: absolute;
    top: 116px;
    right: 0;
    width: 552px;
    height: 524px;

    &::before {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 70px;
      height: 70px;
      content: '';
      background-color: var(--zs-default-color);
    }

    >img {
      width: 510px;
      height: 500px;
      margin-top: 24px;
      margin-left: 44px;
      box-shadow: 50px 50px 0 6px var(--zs-default-color);
    }
  }

  .more {
    position: absolute;
    top: 670px;
  }
}
</style>
