<template>
  <Banner t1="案例详情" t2="行业深耕，客户至上" bg-cls="banner_bg_4" txt-cls="align_r" />
  <nav class="zs_breadcrumb" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/">首页</a></li>
      <li class="breadcrumb-item"><a href="/all_cases">案例展示</a></li>
      <li class="breadcrumb-item active" aria-current="page">案例详情</li>
    </ol>
  </nav>
  <WikiDetailTemplate :page-data="pageData" pager-base-url="/all_cases/case_detail/" style="/* stylelint-disable-next-line custom-property-empty-line-before */
  background: unset;--wiki-detail-template-sidebox-width: calc(3.54 * var(--px100))">
    <template #wk_before_AD>
      <div class="wk_copyright">本文著作权归 {{ this.companyNameS }}咨询&#174; 所有，商业转载请联系获得正式授权，非商业请注明出处</div>
      <div class="wk_about_us">
        <strong>{{ this.companyNameS }}咨询简介</strong><br><br>
        {{ this.companyNameS }}咨询是国内专业的技术服务机构，通过技术标准创新引领，形成了一站式质量技术服务模式：为企业提供检验、检测、认证、审核与培训以及相关延伸服务(含标准服务、能力验证、实验室技术服务、知产服务等)。<br>
        {{ this.companyNameS }}咨询服务能力已全面覆盖到纺织服装及鞋包、婴童玩具及家居生活、电子电器、医学健康、食品及农产品、化妆品及日化用品、石油化工、环境、建材及建筑工程、工业装备及制造、轨道交通、汽车和航空材料、船舶和电子商务等行业的供应链上下游。<br><br><strong>联系{{ this.companyNameS }}咨询</strong><br><br>我们提供审核前的差距分析、培训和预审服务。无论您是在准备迎接一个大客户审核还是认证审核，{{ this.companyNameS }}都将帮助您在接受正式审核前，做出对不符合项的整改完善，以便顺利通过审核。<br><br>
        联系{{ this.companyNameS }}专家团队，详细探讨如何帮助贵司开展更多业务。<br>
        {{ this.companyNameS }}咨询全国热线：{{ this.$store.state.sharedData.companyInfo.formattedTel }} <br>
        电子邮件：{{ this.$store.state.sharedData.companyInfo.mail }}<br><br>
        <a href="#" @click.prevent="onClickPrice" target="_blank">
          <div class="wk_btn_w240">认证&培训 费用报价 &gt;</div>
        </a>
      </div>
    </template>
    <template #wk_right_content>
      <CompCardAbout style="margin: var(--px20);" />
      <CompSideboxListDetail tt="经典案例" :list="sboxList" style="margin: var(--px20);" />
    </template>
  </WikiDetailTemplate>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import WikiDetailTemplate from './WikiDetailTemplate.vue'
import CompSideboxListDetail from '@/components/CompSideboxListDetail.vue'
import CompCardAbout from '@/components/CompCardAbout.vue'
import { useMeta } from 'vue-meta'
import Emitter from '@/js/event-bus'

export default {
  name: 'CaseDetailView',
  components: { Banner, WikiDetailTemplate, CompSideboxListDetail, CompCardAbout },
  methods: {
    onClickPrice() {
      Emitter.emit('onClickIM')
    }
  },
  computed: {
    curId() {
      const id = parseInt(this.$route.params.id) || 0
      return Math.max(id, 1)
    }
  },
  setup() {
    useMeta({
      title: '案例详情',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  created() {
    this.$store.$api.postJson('getCaseDetail/' + this.curId, {}).then((data) => {
      // //console.log('### data => \n', data)
      this.pageData = data.trainCase
      this.pageData.pre = data.preTrainCase
      if (~~this.pageData.pre.id < 0) this.pageData.pre.id = this.pageData.id
      this.pageData.next = data.nextTrainCase
      if (~~this.pageData.next.id < 0) this.pageData.next.id = this.pageData.id
      this.sboxList = data.classicTrainCaseList
      if (this.sboxList.length > 6) this.sboxList = this.sboxList.slice(0, 6)
      this.sboxList.forEach(item => {
        item.url = '/all_cases/case_detail/' + item.id
      })
    })
  },
  data() {
    return {
      pageData: {},
      sboxList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.wk_copyright {
  width: 100%;
  height: 40px;
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
  color: #878787;
  text-align: center;
  background-color: #f5f5f5;
}

.wk_btn_w240 {
  width: 240px;
  height: 42px;
  font-size: 16px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  background-color: var(--zs-default-color);
  border: none;
  border-radius: 5px;
}

.wk_about_us {
  width: 100%;
  padding-top: 34px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  color: #4c4c4c;

  strong {
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
  }
}
</style>
