<template>
  <div class="bk_wrap">
    <div class="bk_img">
      <img :src="imgUrl" id="fid">
      <a :href="dataL.url ?? ''" target="_blank">
        <div class="bk_ovr">
          <div class="bk_ovr_t1">{{ dataL.pub_date }}</div>
          <div class="bk_ovr_t2">
            <!-- <a :href="dataL.url ?? ''" target="_blank" class="nav-link"> -->
            {{ dataL.title }}
            <!-- </a> -->
          </div>
          <div class="bk_ovr_t3 text_between">
            <!-- <a :href="dataL.url ?? ''" target="_blank" class="nav-link"> -->
            {{ dataL.brief }}
            <!-- </a> -->
          </div>
        </div>
      </a>
    </div>
    <div class="bk_ls">
      <ul>
        <li v-for="item of dataR" :key="item">
          <a :href="item.url ?? ''" target="_blank">
            <div class="bk_item">
              <div class="bk_i_d1">{{ item.pub_date.substring(5,) }}</div>
              <div class="bk_i_d2">{{ item.pub_date.substring(0, 4) }}</div>
              <div class="bk_i_t1">
                <!-- <a :href="item.url ?? ''" target="_blank" class="nav-link"> -->
                {{ item.title }}
                <!-- </a> -->
              </div>
              <div class="bk_i_t2 text_between">
                <!-- <a :href="item.url ?? ''" target="_blank" class="nav-link"> -->
                {{ item.brief }}
                <!-- </a> -->
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'comp-list-news',
  props: ['articles', 'imgUrl'],
  computed: {
    dataL() {
      return this.articles ? this.articles[0] : {}
    },
    dataR() {
      return this.articles ? this.articles.slice(1, 5) : []
    }
  },
  mounted() { },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.bk_wrap {
  /* background-color: #f7f7f7; */
  position: relative;
  width: 1400px;
  height: 460px;
  padding-bottom: 62px;
  overflow: hidden;
}

.bk_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 495px;
  height: 460px;

  >img {
    width: 100%;
    height: 100%;
  }
}

.bk_ovr {
  position: absolute;
  right: 20px;
  bottom: 20px;
  left: 20px;
  height: 170px;
  background-color: #fff;
  opacity: 0.9;

  >.bk_ovr_t1 {
    position: absolute;
    top: 20px;
    left: 30px;
    color: #999;
    @include text-single-line-min(14px);
  }

  >.bk_ovr_t2 {
    position: absolute;
    top: 60px;
    left: 30px;
    overflow: hidden;
    color: #333;
    @include text-single-line-min(22px);
  }

  >.bk_ovr_t3 {
    position: absolute;
    top: 102px;
    right: 20px;
    left: 30px;
    font-size: 14px;
    color: #666;
  }
}

.bk_ls {
  position: absolute;
  top: 0;
  left: 495px;
  width: 854px;
  height: 510px;
  padding-left: 52px;

  /* padding-top: 30px; */
}

.bk_item {
  position: relative;
  top: -30px;
  width: 100%;
  height: 125px;

  .active {
    color: var(--zs-default-color);
  }

  >.bk_i_d1 {
    position: absolute;
    top: 35px;
    width: 105px;
    overflow: hidden;
    font-family: Arial-BoldMT, Arial, sans-serif;
    font-family: Arial-BoldMT, var(--font-family-common);
    font-weight: bold;
    color: #333;
    text-align: right;
    @include text-single-line-min(36px);
  }

  >.bk_i_d2 {
    position: absolute;
    top: 71px;
    width: 105px;
    height: 26px;
    font-family: Arial, sans-serif;
    font-family: Arial, var(--font-family-common);
    font-size: 16px;
    line-height: 26px;
    color: #333;
    text-align: right;
  }

  >.bk_i_t1 {
    position: absolute;
    top: 35px;
    margin-left: 140px;
    font-size: 22px;
    line-height: 22px;
    color: #333;
  }

  >.bk_i_t2 {
    position: absolute;
    top: 76px;
    max-height: 3em;
    margin-left: 140px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
    color: #999;
  }
}

li:not(:last-child)>.bk_item {
  border-bottom: 1px solid #E8E8E8;
}

.bk_ls a,
.bk_img a {
  text-decoration: none;
  text-transform: none;

  &:hover {
    .bk_ovr,
    .bk_item {
      .bk_i_d1,
      .bk_i_t1,
      .bk_ovr_t1,
      .bk_ovr_t2 {
        color: var(--zs-default-color);
      }
    }
  }
}
</style>
