import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import ShowAllCases from '../views/ShowAllCasesView.vue'
import ShowAllProducts from '../views/ShowAllProductsView.vue'
import WhyChooseUs from '../views/WhyChooseUsView.vue'
import ContactUs from '../views/ContactUsView.vue'
import Wiki from '../views/WikiListView.vue'
import WikiDetail from '../views/WikiDetailView.vue'
import CaseDetail from '../views/CaseDetailView.vue'
import ProductDetail from '../views/ProductDetailView.vue'

const routes = [
  // {
  //   path: '/:catchAll(.*)',
  //   redirect: '/'
  // },
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: 'index.html',
        redirect: '/',
      }
    ]
  },
  {
    path: '/all_cases/:id(\\d*)?',
    name: 'all_cases',
    component: ShowAllCases,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/all_cases/' + to.params.id } },
      }
    ]
  },
  {
    path: '/all_products/:id(\\d*)?',
    name: 'all_products',
    component: ShowAllProducts,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/all_products/' + to.params.id } },
      }
    ]
  },
  {
    path: '/wiki/:type(\\d*)?/:id(\\d*)?',
    name: 'wiki',
    component: Wiki,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/wiki/' + to.params.type + (to.params.type !== '' ? '/' : '') + to.params.id } },
      }
    ],
  },
  {
    path: '/all_products/product_detail/:id(\\d*)',
    name: 'product_detail',
    component: ProductDetail,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/all_products/product_detail/' + to.params.id } },
      }
    ],
    meta: {
      active: '/all_products'
    }
  },
  {
    path: '/wiki/wiki_detail/:id(\\d*)',
    name: 'wiki_detail',
    component: WikiDetail,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/wiki/wiki_detail/' + to.params.id } },
      }
    ],
    meta: {
      active: '/wiki'
    }
  },
  {
    path: '/all_cases/case_detail/:id(\\d*)',
    name: 'case_detail',
    component: CaseDetail,
    children: [
      {
        path: 'index.html',
        redirect: to => { return { path: '/all_cases/case_detail/' + to.params.id } },
      }
    ],
    meta: {
      active: '/all_cases'
    }
  },
  {
    path: '/why_choose_us',
    name: 'why_choose_us',
    component: WhyChooseUs,
    children: [
      {
        path: 'index.html',
        redirect: '/why_choose_us',
      }
    ],
  },
  {
    path: '/contact_us',
    name: 'contact_us',
    component: ContactUs,
    children: [
      {
        path: 'index.html',
        redirect: '/contact_us',
      }
    ],
  },
  {
    path: '/about',
    name: 'about',
    children: [
      {
        path: 'index.html',
        redirect: '/about',
      }
    ],
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (window.__PRERENDER_INJECTED) {
    next()
    return
  }

  if ( // 移动端
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|Opera Mini)/i
    )
  ) {
    if (to.path.indexOf('/wap/') === -1 && to.path !== '/wap') {
      // next({ path: '/wap/' + to.path.replace(/^\/+/, '') }) // 还是使用的当前 router，所以不行
      window.location.href = '/wap/' + to.path.replace(/^\/+/, '')
      return
    }
  } else { // PC端
    if (to.path.indexOf('/wap/') !== -1) {
      window.location.href = to.path.replace('/wap/', '/')
      return
    } else if (to.path === '/wap') {
      window.location.href = '/index.html'
      return
    }
  }

  next()
})

router.afterEach(
  () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' }) // 重置页面位置，instant 不是标准实现
  }
)

export default router
