<template>
  <Banner t1="服务优势" t2="行业深耕，客户至上" bg-cls="banner_bg_2" txt-cls="align_r" />
  <div class="wcu_body">
    <div class="wrap">
      <div class="wcu_t">服务优势</div>
      <div class="wcu_ls">
        <div v-for="(item, index) in list" :key="index" :class="'item item_' + ++index">
          <div class="txt">
            <div class="t1">{{ item.t }}</div>
            <div class="t2 text_between">{{ item.st }}</div>
          </div>
          <div class="ic"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'WhyChooseUs',
  components: {
    Banner,
  },
  setup() {
    useMeta({
      title: '服务优势',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  data() {
    return {
      list: [
        { t: '专人', st: '每一个项目，我们都会安排专人对接负责' },
        { t: '专心', st: '用心为客户提供高效、快捷、方便的保姆式服务' },
        { t: '专注', st: '我们源于纺织行业，也专注于为纺织行业服务' },
        { t: '专业', st: '十多年咨询认证服务经验，千锤百炼，高效专业' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.wcu_t {
  margin-top: 92px;
  margin-bottom: 63px;
  font-weight: bold;
  color: #333;
  text-align: center;
  @include text-single-line-min(36px);
}

.wcu_ls {
  display: flex;
  justify-content: space-between;
}

@mixin wcu-item($index) {
  background: url("../assets/images/wcu-bg-s-" + $index + ".jpg") center top no-repeat;

  .ic {
    background: url("../assets/images/wcu-icon-" + $index + ".svg") center top no-repeat;
  }
}

.item {
  width: 334px;
  height: 478px;

  &.item_1 {
    @include wcu-item(1)
  }

  &.item_2 {
    @include wcu-item(2)
  }

  &.item_3 {
    @include wcu-item(3)
  }

  &.item_4 {
    @include wcu-item(4)
  }

  .ic {
    width: 60px;
    height: 60px;
    margin: calc(-213px - 30px) auto 0 auto;
    background: var(--zs-default-color);
    border-radius: 50%;
  }

  .txt {
    width: 287px;
    height: 213px;
    margin: 224px auto 0;
    background: #F8F8F8;

    >.t1 {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 14px;
      margin: 0 auto;
      font-size: 30px;
      font-weight: bold;
      line-height: 1;
      color: var(--zs-t1-color);
      text-align: center;
    }

    >.t2 {
      width: 163px;
      margin: auto;
      font-size: 18px;
      line-height: 1.5;
      color: #333;
    }
  }

}

.wcu_body {
  position: relative;
  width: 100%;
  max-width: 2560px;
  height: 862px;
  overflow: hidden;
  background: url("../assets/images/wcu-bg-2.jpg") no-repeat;

  @media (min-width: 2560px) {
    // margin-left: calc(50vw - 1280px);
    background-position-y: calc((2560px - 1920px) / -5);
  }

  @media (min-width: 1920px ) and (max-width: 2560px) {
    background-position-y: calc((100vw - 1920px) / -5);
  }

  @media (max-width: 1920px ) {
    background-position-y: 0;
  }

  background-size: cover;
}
</style>
