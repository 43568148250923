<template>
  <Banner t1="认证咨询百科" t2="行业深耕，客户至上" bg-cls="banner_bg_5" txt-cls="align_l" />
  <div class="wiki_tabs_wrap">
    <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
      <li class="nav-item w100_pd30" role="presentation">
        <a :class="'nav-link ' + getTabItemClass(1)" id="wiki-1st-tab" href="/wiki/1">标准介绍</a>
      </li>
      <li class="nav-item w100_pd30" role="presentation">
        <a :class="'nav-link ' + getTabItemClass(2)" id="wiki-2nd-tab" href="/wiki/2">行业动态</a>
      </li>
      <li class="nav-item w100_pd30" role="presentation">
        <a :class="'nav-link ' + getTabItemClass(3)" id="wiki-3rd-tab" href="/wiki/3">政策标准</a>
      </li>
      <li class="nav-item w100_pd30" role="presentation">
        <a :class="'nav-link ' + getTabItemClass(4)" id="wiki-4th-tab" href="/wiki/4">常见问题</a>
      </li>
    </ul>
  </div>
  <div class="wiki_bg">
    <div class="wrap" style="display: flex;flex-direction: column;">
      <div class="ls_wrap">
        <ul>
          <li v-for="(item, index) in apiData.records" :key="index"><a :href="'/wiki/wiki_detail/' + item.id" target="_blank">
              <div class="li_wrap">
                <WikiCard :d1="item.d1" :d2="item.d2" :t1="item.title"
                  :t2="item.description" />
              </div>
            </a></li>
        </ul>
      </div>

      <nav aria-label="all wiki">
        <ul class="pagination justify-content-center">
          <li class="page-item">
            <a class="page-link" :href="getPrevUrl()" aria-label="Previous">
              <span aria-hidden="true">&lsaquo;</span>
            </a>
          </li>
          <li v-for="(item, index) in Array(apiData.pages)" :class="getPageItemClass(index)" :key="index">
            <a class="page-link" :href="getPageItemUrl(index)">{{ index + 1 }} </a>
          </li>
          <li class="page-item">
            <a class="page-link" :href="getNextUrl()" aria-label="Next">
              <span aria-hidden="true">&rsaquo;</span>
            </a>
          </li>
        </ul>
      </nav>

    </div>
  </div>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import WikiCard from '@/components/CompCardWiki.vue'
import { inject } from 'vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'WikiView',
  components: {
    Banner,
    WikiCard
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    category() {
      return Math.max(~~this.$route.params.type, 1)
    },
    curPage() {
      const curPage = parseInt(this.$route.params.id) || 0
      return Math.max(curPage, 1)
    }
  },
  methods: {
    getTabItemClass(el) {
      return ~~this.category === ~~el ? 'active' : ''
    },
    getPageItemClass(index) {
      if (index + 1 === this.apiData.current) {
        return 'page-item active'
      }
      return 'page-item'
    },
    getPageItemUrl(index) {
      return '/' + this.routeName + '/' + this.category + '/' + (index + 1)
    },
    getPrevUrl() {
      return '/' + this.routeName + '/' + this.category + '/' + Math.max(1, this.apiData.current - 1)
    },
    getNextUrl() {
      return '/' + this.routeName + '/' + this.category + '/' + Math.min(this.apiData.pages, this.apiData.current + 1)
    }
  },
  setup() {
    useMeta({
      title: '认证咨询百科',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
    const moment = inject('moment')
    return { moment }
  },
  data() {
    return { apiData: { pages: 1, size: 12, current: 1, records: [] } }
  },
  created() {
    this.$store.$api.postJson('wikiList', { curPage: this.curPage, pageSize: 8, type: this.category }).then((data) => {
      // //console.log('### data => \n', data)
      this.apiData = data
      this.apiData.records.forEach(item => {
        item.d1 = this.moment.utc(item.publishTime ?? {}).local().format('DD')
        item.d2 = this.moment.utc(item.publishTime ?? {}).local().format('MM/YYYY')
      })
    })
  },
  mounted() {
    // //console.log(this.$route.path, ', => mounted, ', this.$route.params, ', curPage =', this.curPage)
  }
}
</script>

<style lang="scss" scoped>
.wiki_tabs_wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}

.wiki_bg {
  width: 100%;
  max-width: 2560px;
  height: 1065px;
  background: url('../assets/images/wiki-bg-2.jpg') center/cover no-repeat;
}

.ls_wrap {
  width: 1400px;
  padding-bottom: 43px;
  margin-top: 32px;
  clear: both;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      width: 684px;
      height: calc(189px + 32px);
    }

    li a {
      text-decoration: none;
      text-transform: none;
    }
  }

}

.li_wrap {
  position: relative;
  width: 684px;
  height: 189px;
  background-color: #f5f5f5;
}
</style>
