<template>
  <nav class="global-nav">
    <div class="wrap">
      <div class="left-part">
        <router-link to="/" active-class="current" exact class="logo-link">
          <i class="icon icon-nav-logo"></i></router-link>
        <div class="main-nav">
          <router-link to="/" active-class="current" exact class="nav-link">
            <span class="txt">企业首页</span></router-link>
          <router-link v-if="isShowNavMenu" :to="{ name: 'all_products' }" active-class="current" @mouseover="onDropDownOpen"
            @mouseleave="onDropDownClose" id="all-products"
            :class="{ 'nav-link': true, 'current': $route.meta.active === '/all_products' }">
            <span class="txt">服务项目
              <CompDropdownMenu :is-open="isOpen" :api-data="this.$store.state.sharedData.projectMenuInfo" />
            </span>
            <svg style="display: none;">
              <filter id="red-wash" color-interpolation-filters="sRGB">
                <feColorMatrix type="matrix" values="0 0 0 0 0.8  0 0 0 0 0.1  0 0 0 0 0.1  0 0 0 1 0" />
              </filter>
            </svg>
          </router-link>
          <router-link v-else :to="{ name: 'all_products' }" active-class="current"
            :class="{ 'nav-link': true, 'current': $route.meta.active === '/all_products' }">
            <span class="txt">服务项目</span>
          </router-link>
          <router-link to="/about" active-class="current" exact class="nav-link">
            <span class="txt">关于我们</span></router-link>
          <router-link :to="{ name: 'all_cases' }" active-class="current"
            :class="{ 'nav-link': true, 'current': $route.meta.active === '/all_cases' }">
            <span class="txt">案例展示</span></router-link>
          <router-link to="/why_choose_us" exact active-class="current" class="nav-link">
            <span class="txt">服务优势</span></router-link>
          <router-link :to="{ name: 'wiki' }" active-class="current"
            :class="{ 'nav-link': true, 'current': $route.meta.active === '/wiki' }">
            <span class="txt">认证咨询百科</span></router-link>
          <router-link to="/contact_us" active-class="current" class="nav-link">
            <span class="txt">联系我们</span></router-link>
          <div class="nav-contact">
            <i class="icon icon-ic-tel-red-2 nav-contact-tel trin-trin"></i>
            <span>{{ this.$store.state.sharedData.companyInfo.tel }}</span>
            <div class="template-enhance-animation-light"></div>
          </div>
        </div>
      </div>
      <div class="right-part">
        <div class="right-placehodler"></div>
      </div>
    </div>
  </nav>
</template>

<script>
import CompDropdownMenu from '@/components/CompDropdownMenu.vue'

export default {
  name: 'comp-navigation',
  props: ['backend'],
  components: { CompDropdownMenu },
  computed: {
    isShowNavMenu() {
      return this.$store.state.sharedData.projectMenuInfo?.length > 0
    }
  },
  methods: {
    onDropDownOpen() {
      this.isOpen = true
    },
    onDropDownClose() {
      this.isOpen = false
    }
  },
  data() {
    return {
      isOpen: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.global-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 86px;
  background: #fff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);

  .backend & {
    position: static;
    top: auto;
    z-index: auto;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    width: 1400px;
    margin: auto;
  }

  .left-part {
    display: flex;
  }

  .right-part {
    display: flex;

    @media (max-width: 750px) {
      display: none;
    }
  }

  .right-placehodler {
    flex: 1 0 auto;
    min-width: 30vw;
    margin-left: -1px;
    background-color: var(--zs-default-color);
  }

  .logo-link {
    display: block;
    margin-right: 100px;
    margin-left: -10px;

    .icon {
      margin-top: 10px;
    }
  }

  .main-nav {
    display: flex;
    margin-left: 48px;

    @media (max-width: 1000px) {
      margin-left: 25px;
    }

    .icon {
      margin-right: 12px;
      vertical-align: -4px;

      @media (max-width: 1000px) {
        margin-right: 0;
      }
    }
  }

  .nav-link {
    position: relative;
    padding: 0 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 86px;
    color: #222;
    white-space: nowrap;

    &.current {
      color: var(--zs-default-color);
    }

    &.current::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 55%;
      height: 4px;
      content: "";
      background: var(--zs-default-color);
      border-radius: 0;
      transform: translateX(-50%);
    }

    @media (max-width: 1000px) {
      .txt {
        display: inline-block;
      }
    }

    @media (max-width: 375px) {
      padding: 0 15px;
    }
  }

  .nav-contact {
    display: flex;
    width: 500px;
    margin-left: 40px;
    font-size: 18px;
    line-height: 86px;
    color: #fff;
    background-color: var(--zs-default-bgcolor);

    .nav-contact-tel {
      position: relative;
      top: calc(50% - 15px);
      margin-right: 10px;
      margin-left: 33px;
    }
  }
}
</style>

<style lang="scss" scoped>
.template-enhance-animation-light {
  position: absolute;
  top: 0;
  width: calc(6.1625 / 2.8125 * 86px);
  height: 86px;
  pointer-events: none;
  background-image: url('https://fe-resource.cdn.bcebos.com/mip/fengming/form/light_animate.png');
  background-size: calc(6.1625 / 2.8125 * 86px) 86px;
  transform: translateZ(0);

  /* -webkit-animation: templateSearchLights-12df4446 3s linear infinite forwards; */
  animation: template-search-lights 3s linear infinite forwards;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;
}

@keyframes template-search-lights {
  0% {
    opacity: 0;
    transform: translate(calc(-5.6612 / 2.8125 * 86px));
  }

  66% {
    opacity: 1;
    transform: translate(calc(-5.6612 / 2.8125 * 86px));
  }

  100% {
    transform: translate(calc(24.9094 / 2.8125 * 86px));
  }
}

/******************************************   */
#all-products {
  text-decoration: none;
  cursor: pointer;

  >span::after {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(100% - 25px);
    display: inline-block;
    width: 20px;
    height: 16px;
    line-height: 16px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 700 600'%3E%3Cpath d='M623.44 280c0 73.062-28.438 141.75-80.062 193.38s-120.31 80.062-193.38 80.062-141.75-28.438-193.38-80.062S76.556 353.07 76.556 280s28.438-141.75 80.062-193.38 120.31-80.062 193.38-80.062 141.75 28.438 193.38 80.062S623.44 206.93 623.44 280zm-525 0c0 138.69 112.88 251.56 251.56 251.56S601.56 418.68 601.56 280 488.68 28.44 350 28.44 98.44 141.32 98.44 280z'/%3E%3Cpath d='m431.27 211.31 28.109 27.891-109.38 109.48-109.48-109.48 28.109-27.891 81.375 81.266z'/%3E%3C/svg%3E");
    transition: all .2s ease;
    object-fit: contain;

    // 用 mask 实现也可以
    // left: calc(100% - 24px);
    // content: '';
    // background-color: red;
    // mask-image: url('@/assets/images/arrow-down.svg');
    // mask-size: contain;
    // mask-position: center;
  }

  &:hover {
    >span::after {
      transform: rotate(-180deg);
    }
  }

  &.current {
    >span::after {
      filter: url("#red-wash");
    }
  }
}

.trin-trin {
  animation-name: trin;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes trin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  20%,
  32%,
  44%,
  56%,
  68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  23%,
  35%,
  47%,
  59%,
  71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  26%,
  38%,
  50%,
  62%,
  74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

  29%,
  41%,
  53%,
  65%,
  77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 0deg);
  }

}
</style>
