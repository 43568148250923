<template>
  <div class="wrap">
    <div class="zs_main_T ptpb">
      <span>认证咨询百科</span>
    </div>
    <div class="zs_hot_tabs_wrap">
      <ul class="nav nav-pills /*mb-3*/" id="pills-tab" role="tablist">
        <li class="nav-item w123" role="presentation">
          <button class="nav-link active" id="pills-1st-tab" data-bs-toggle="pill" data-bs-target="#pills-1st"
            type="button" role="tab" aria-controls="pills-1st" aria-selected="true">标准介绍</button>
        </li>
        <li class="nav-item w123" role="presentation">
          <button class="nav-link" id="pills-2nd-tab" data-bs-toggle="pill" data-bs-target="#pills-2nd" type="button"
            role="tab" aria-controls="pills-2nd" aria-selected="false">行业动态</button>
        </li>
        <li class="nav-item w123" role="presentation">
          <button class="nav-link" id="pills-3rd-tab" data-bs-toggle="pill" data-bs-target="#pills-3rd" type="button"
            role="tab" aria-controls="pills-3rd" aria-selected="false">政策标准</button>
        </li>
        <li class="nav-item w123" role="presentation">
          <button class="nav-link" id="pills-4th-tab" data-bs-toggle="pill" data-bs-target="#pills-4th" type="button"
            role="tab" aria-controls="pills-4th" aria-selected="false">常见问题</button>
        </li>
      </ul>
      <div class="nav-item" style="margin-top: -3px;margin-left:auto;">
        <label class="more more_large"><router-link to="/wiki/" exact>更多 &gt;</router-link></label>
      </div>
    </div>

    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-1st" role="tabpanel" aria-labelledby="pills-1st-tab" tabindex="0">
        <ListNews :articles="getArticlesByType(1)" :img-url="require(`../assets/images/main-wiki-bg-1.jpg`)" />
      </div>
      <div class="tab-pane fade" id="pills-2nd" role="tabpanel" aria-labelledby="pills-2nd-tab" tabindex="0">
        <ListNews :articles="getArticlesByType(2)" :img-url="require(`../assets/images/main-wiki-bg-2.jpg`)" />
      </div>
      <div class="tab-pane fade" id="pills-3rd" role="tabpanel" aria-labelledby="pills-3rd-tab" tabindex="0">
        <ListNews :articles="getArticlesByType(3)" :img-url="require(`../assets/images/main-wiki-bg-3.jpg`)" />
      </div>
      <div class="tab-pane fade" id="pills-4th" role="tabpanel" aria-labelledby="pills-4th-tab" tabindex="0">
        <ListNews :articles="getArticlesByType(4)" :img-url="require(`../assets/images/main-wiki-bg-4.jpg`)" />
      </div>
    </div>

  </div>
</template>

<script>

import ListNews from '@/components/CompListNews.vue'
import { inject } from 'vue'

export default {
  name: 'comp-home-wiki-news',
  props: ['apiData'],
  components: {
    ListNews
  },
  setup() {
    const moment = inject('moment')
    return { moment }
  },
  data() {
    const getArticlesByType = (type) => {
      return this.apiData[type - 1]?.map((item) => {
        return {
          pub_date: this.moment.utc(item.publishTime).local().format('YYYY-MM-DD'),
          title: item.title,
          brief: item.description,
          url: '/wiki/wiki_detail/' + item.id
        }
      })
    }
    return {
      getArticlesByType
    }
  }
}
</script>

<style lang="scss" scoped>
.zs_hot_tabs_wrap {
  display: flex;
  margin-bottom: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.nav-item label {
  /* display: inline; */
  float: right;
}

.zs_main_T {
  &.ptpb {
    padding-top: 63px;
    padding-bottom: 15px;
  }
}
</style>
