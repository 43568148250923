<template>
  <div id="map" class="map_container">
  </div>
</template>
<script>
// import BaiduMap from 'BaiduMap'
import BaiduMap from '../js/map'

export default {
  name: 'comp-map-view',
  mounted() {
    if (window.__PRERENDER_INJECTED) return
    BaiduMap.init()
      .then((BMap) => {
        const CustomAnnotation = BaiduMap.createCustomAnnotationCls()
        const CustomInfoBox = BaiduMap.createCustomInfoBoxCls()

        const map = new BMap.Map('map')
        // //console.log('Map created ', map)
        const point = new BMap.Point(this.companyBmapLL.long, this.companyBmapLL.lat) // 创建中心点坐标
        map.centerAndZoom(point, 15)
        map.enableScrollWheelZoom(true)

        // 添加自定义锚点
        const annotation = new CustomAnnotation(map.getCenter(), 43, 'var(--zs-default-color-rgb)')
        map.addOverlay(annotation)
        // annotation._div.firstChild.className = 'anime_zoom'
        // //console.log(annotation)

        // 添加自定义提示窗口
        const infoBox = new CustomInfoBox(map.getCenter())
        map.addOverlay(infoBox)
      })
  }
}
</script>

<style lang="scss">
#bmap-circle-outer::after {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(var(--zs-default-bgcolor-light-rgb) / 30%);
  transform: scale(1);
  animation: bmap-pulse 2s infinite;
}

@keyframes bmap-pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(var(--zs-default-bgcolor-light-rgb) / 30%);
    transform: scale(0.95);
  }

  70% {
    box-shadow: 0 0 0 15px rgb(0 0 0 / 0%);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
    transform: scale(0.95);
  }
}

.anime_zoom {
  animation: zoom-in-zoom-out 2s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  10% {
    transform: scale(1, 1);
  }

  80% {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}
</style>

<style lang="scss" scoped>
.map_container {
  width: 100%;
  height: 341px;
  margin-top: 17px;
  margin-bottom: 30px;
  background-color: #fff;
}
</style>
