<template>
  <Banner t1="服务项目" t2="行业深耕，客户至上" bg-cls="banner_bg_1" txt-cls="align_r" />
  <nav class="zs_breadcrumb" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="/">首页</a></li>
      <li class="breadcrumb-item"><a href="/all_products">服务项目</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ pageData?.title }}</li>
    </ol>
  </nav>
  <WikiDetailTemplate :page-data="pageData" pager-base-url="/all_products/product_detail/" style="/* stylelint-disable-next-line custom-property-empty-line-before */
  background: unset;--wiki-detail-template-sidebox-width: calc(3.54 * var(--px100))">
    <template #wk_before_AD>
      <div class="wk_about_us">
        <strong>{{ this.companyNameS }}咨询简介</strong><br><br>
        {{ this.companyNameS }}咨询是国内专业的技术服务机构，通过深耕行业技术标准，形成了高效的一站式质量技术服务模式：为企业提供检验、检测、认证、审核与培训以及相关延伸服务(含标准服务、能力验证、实验室技术服务等)。<br>
        {{ this.companyNameS }}咨询服务能力已高度覆盖纺织服装及鞋包、家居生活、农产品、日化用品、环保卫材、电商等行业的供应链上下游。<br><br><strong>联系{{ this.companyNameS }}咨询</strong><br><br>我们提供审核前全面的分析、培训和预审服务。无论是甲方客户审核还是认证审核，{{ this.companyNameS }}都将帮助您在正式审核前做好整改完善，顺利通过正式审核。<br><br>
        联系{{ this.companyNameS }}专家团队，详细探讨如何帮助贵司开展更多业务。<br>
        {{ this.companyNameS }}咨询全国热线：{{ this.$store.state.sharedData.companyInfo.formattedTel }} <br>
        电子邮件：{{ this.$store.state.sharedData.companyInfo.mail }}<br><br>
      </div>
    </template>
    <template #wk_right_content>
      <div style="margin-top: var(--px20);margin-left: var(--px10);">
        <!-- <SideboxFloat> -->
        <CompCardAbout />
        <CompSideboxListIcon tt="服务项目" :list="projectList" />
        <!-- </SideboxFloat> -->
      </div>
    </template>
  </WikiDetailTemplate>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import WikiDetailTemplate from './WikiDetailTemplate.vue'
import CompSideboxListIcon from '@/components/CompSideboxListIcon.vue'
import CompCardAbout from '@/components/CompCardAbout.vue'
// import SideboxFloat from '@/components/CompSideboxFloatWrap.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'ProductDetailView',
  components: { Banner, WikiDetailTemplate, CompSideboxListIcon, CompCardAbout },
  computed: {
    curId() {
      const id = parseInt(this.$route.params.id) || 0
      return Math.max(id, 1)
    }
  },
  data() {
    return {
      pageData: {},
      projectList: [],
    }
  },
  created() {
    this.$store.$api.postJson('getProjectDetail/' + this.curId, {}).then((data) => {
      // //console.log('### data => \n', data)
      this.pageData = data.project
      this.projectList = data.hotProjectList
      if (this.projectList.length > 8) this.projectList = this.projectList.slice(0, 8)
      this.projectList.forEach(item => {
        item.title = item.name1
        item.url = '/all_products/product_detail/' + item.id
      })
      this.pageData.noPager = true
    })
  },
  setup() {
    useMeta({
      title: '服务项目详情',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
}
</script>

<style lang="scss" scoped>
.wk_about_us {
  width: 100%;
  padding-top: 34px;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  color: #4c4c4c;

  strong {
    font-size: 24px;
    font-weight: normal;
    color: #1a1a1a;
  }
}
</style>
