<template>
  <div class="zs_card">
    <div class="c_img"><div class="c_img_a" :src="imgUrl ?? ''" :style="{ background: 'url(' + imgUrl + ') center/cover no-repeat' }"></div>
    </div>
    <div class="c_tt">{{ t1 ?? '' }}</div>
    <div class="cc_tt_sub">{{ t2 ?? '' }}</div>
    <div class="cc_link">{{ btnTitle ?? '了解详情' }} &gt;</div>
    <div class="cc_border"></div>
  </div>
</template>
<script>
export default {
  name: 'comp-product-card',
  props: ['btnTitle', 't1', 't2', 'imgUrl'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.cc_border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--zs-default-color);
}

.zs_card {
  height: 100%;

  // background-color: red;
  transition: all 0.3s linear;

  .cc_border {
    transition: all 0.3s linear;
  }

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 20%);

    .cc_border {
      width: 280px;
    }
  }
}

.c_img {
  position: absolute;
  top: 29px;
  left: 0;
  display: flex;
  justify-content: center;
  width: 280px;
  height: 160px;
  text-align: center;

  .c_img_a,img {
    width: 160px;
    height: 160px;
    border-radius: 50%;

    // aspect-ratio: auto;
  }
}

.c_tt {
  position: absolute;
  top: 206px;
  left: 0;
  width: 280px;
  height: 38px;
  font-size: 18px;
  font-weight: bold;
  line-height: 38px;
  color: #1a1a1a;
  text-align: center;
}

.cc_tt_sub {
  position: absolute;
  top: 234px;
  left: 0;
  width: 280px;
  height: 34px;
  font-size: 14px;
  line-height: 34px;
  color: #777;
  text-align: center;
}

.cc_link {
  position: absolute;
  top: 273px;
  left: 0;
  width: 280px;
  height: 34px;
  font-size: 14px;
  font-weight: bold;
  line-height: 34px;
  color: #1a1a1a;
  text-align: center;
}
</style>
