<template>
  <HomeBanner :api-data="bannerIdList" />
  <HotProducts :api-data="hotProductIdList" />
  <AboutUs />
  <AllCases :api-data="trainCaseList" />
  <WikiNews :api-data="mainWikiList" />

  <!-- DEBUG -->
  <!-- <div style="height:200px;background-color:#999;"></div> -->
</template>

<script>
import HomeBanner from '@/components/CompHomeBanner.vue'
import HotProducts from '@/components/CompHomeHotProducts.vue'
import AboutUs from '@/components/CompHomeAboutUs.vue'
import AllCases from '@/components/CompHomeAllCases.vue'
import WikiNews from '@/components/CompHomeWikiNews.vue'

import { onMounted } from 'vue'

export default {
  name: 'HomeView',
  components: {
    HomeBanner,
    HotProducts,
    AboutUs,
    AllCases,
    WikiNews,
  },
  setup() {
    onMounted(() => {
      // //console.log('on mounted HomeView')
    })
  },
  created() {
    this.$store.$api.postJson('mainHome', { curPage: 1, pageSize: 8, companyType: '1' }).then(
      data => {
        // //console.log('### data => \n', data)
        this.companyInformation = data.companyInformation
        this.mainWikiList = data.mainWikiList
        this.trainCaseList = data.trainCaseList
        this.$store.commit('saveCompanyInfo', this.companyInformation)
        this.hotProductIdList = data.projectIdList ?? []
        this.bannerIdList = data.bannerIdList ?? []
        // console.log(this.hotProductIdList, this.bannerIdList)
      })
  },
  data() {
    return { companyInformation: {}, mainWikiList: {}, trainCaseList: {}, hotProductIdList: [], bannerIdList: [] }
  }
}
</script>
