<template>
  <div class="wk_pager_PN">
    <span>上一篇：<a :href="preUrl">{{ preT }}</a></span>
    <span>下一篇：<a :href="nextUrl">{{ nextT }}</a></span>
  </div>
</template>

<script>
export default {
  name: 'comp-wiki-pager-pn',
  props: ['preUrl', 'nextUrl', 'preT', 'nextT'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.wk_pager_PN {
  width: 100%;
  height: 92px;
  padding-top: 20px;
  overflow: hidden;
  font-size: 14px;
  color: #1a1a1a;
  border-top: 1px solid #EAEAEA;

  span {
    display: block;
    width: 100%;
    height: 34px;
    line-height: 34px;

    >a:hover {
      font-weight: bold;
      color: #1a1a1a;
      text-decoration: none;
    }
  }
}
</style>
