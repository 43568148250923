<template>
  <div class="banner_w100">
    <div class="wrap ctct">
      <div class="t1">联系我们</div>
      <div class="clearfix">
        <div class="X1 Y1">
          <AddrItem :icon-url="require('../assets/images/ic-addr.svg')" t1="地址" :t2="apiData.address" />
          <AddrItem :icon-url="require('../assets/images/ic-tel.svg')" t1="电话" :t2="apiData.tel" />
          <AddrItem :icon-url="require('../assets/images/ic-web.svg')" t1="网址" :t2="apiData.site" />
          <AddrItem :icon-url="require('../assets/images/ic-mail.svg')" t1="邮箱" :t2="apiData.mail" />
          <AddrItem :icon-url="require('../assets/images/ic-wechat.svg')" t1="微信扫一扫" :img-url="apiData.wxUrl" />
        </div>
        <div class="X1 Y2">
          <form ref="contactForm" class="row g-2 form_wrap needs-validation" v-on:submit.prevent="debounceSubmit" novalidate>
            <div class="col-md-6" style="max-width: 48%;">
              <input type="text" class="form-control txt txt_field" placeholder="主题" v-model="info.title" />
            </div>
            <div class="col-md-6" style="max-width: 48%;">
              <input type="text" class="form-control txt txt_field" placeholder="如何称呼您？" v-model="info.name" required />
              <div class="invalid-feedback">请填写您的称呼</div>
            </div>
            <div class="col-md-6" style="max-width: 48%;">
              <input type="text" class="form-control txt txt_field" placeholder="联系电话" v-model="info.tel" required />
              <div class="invalid-feedback">请填写您的联系方式</div>
            </div>
            <div class="col-md-6" style="max-width: 48%;">
              <input type="text" class="form-control txt txt_field" placeholder="E-mail" v-model="info.email" />
            </div>
            <div class="col-md-12">
              <textarea class="form-control txt txt_area" placeholder="请留下您的需求，我们将尽快联系您。" v-model="info.msg" required />
              <div class="invalid-feedback">请填写您想咨询的内容</div>
            </div>
            <div class="col-12">
              <button class="btn1" type="submit">发送您的需求</button>
            </div>

          </form>
          <CompToast ref="toast" />
        </div>
      </div>
      <CompMapView />
    </div>
  </div>
</template>

<script>
import AddrItem from '@/components/CompContactUsAddr.vue'
import CompMapView from '@/components/CompMapView.vue'
import { computed } from 'vue'
// import axios from 'axios'
import { useMeta } from 'vue-meta'
import CompToast from '@/components/CompToast.vue'
import debounce from '@/js/debounce'

export default {
  name: 'ContactUs',
  components: {
    AddrItem,
    CompMapView,
    CompToast
  },
  methods: {
    submitForm(event) {
      const form = event.target
      if (!form.checkValidity()) {
        event.stopPropagation()
        form.classList.add('was-validated')
        return
      }
      form.classList.add('was-validated')

      this.$store.$api.postJson('advice', {
        content: this.info.msg,
        mail: this.info.email,
        name: this.info.name,
        subject: this.info.title,
        tel: this.info.tel
      }).then((data) => {
        // //console.log('### data => ', data)
        this.$refs.toast.show('提交成功，感谢您的来访!', true)
        this.info = {}
        this.$refs.contactForm.classList = 'row g-2 form_wrap needs-validation'
      }).catch(() => {
        // if (error.response) {
        //   // //console.log('###', error.response.status, error.response.headers, error.response.data)
        // } else if (error.request) {
        //   // //console.log('###', error.request)
        // }
        // //console.log('### POST advice', error.toJSON())
        this.$refs.toast.show('网络错误，请稍后重试!')
        this.info = {}
        this.$refs.contactForm.classList = 'row g-2 form_wrap needs-validation'
      })
    },
  },
  setup() {
    useMeta({
      title: '联系我们',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  created() {
    // //console.log('created =>', this.$route.name)
    this.$store.dispatch('updateCommonInfo')
  },
  data() {
    // //console.log(this.$store)
    const apiData = computed(() => {
      return this.$store.state.sharedData.companyInfo
    })
    const debounceSubmit = debounce(async (event) => {
      // //console.log('### debounce calling submit')
      this.submitForm(event)
    }, 3000)
    return { apiData, info: { title: '', name: '', tel: '', email: '', msg: '' }, debounceSubmit }
  },
}
</script>

<style lang="scss" scoped>
.banner_w100 {
  position: relative;
  width: 100%;
  height: 947px;
  overflow: hidden;
  background: url("../assets/images/contact-bg-1.jpg") center/cover no-repeat;
}

.ctct {
  .t1 {
    position: relative;
    margin-top: 43px;
    @extend %about-t1w;
  }

  .btn1 {
    position: relative;
    top: 32px;
    width: 178px;
    height: 50px;
    font-size: 14px;
    color: #fff;
    background: var(--zs-default-color);
    border: none;
    border-radius: 0;
  }
}

.X1 {
  position: relative;
  height: 470px;
  margin-top: 23px;
  background-color: #fff;

  &.Y1 {
    float: left;
    width: 416px;
  }

  &.Y2 {
    float: right;
    width: 960px;
  }
}

.form_wrap {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // width: 100%;
  margin: 0 32px;

  * {
    box-sizing: border-box;
  }

  .invalid-feedback {
    // margin-top: -20px;
    position: absolute;
  }
}

.txt {
  // box-sizing: border-box;
  padding: 8px 14px;
  margin-top: 18px;
  font-size: 14px;
  border: 1px solid #D9D9D9;
  border-radius: unset;
  caret-color: #333;

  &:focus {
    border: 1px solid rgb(var(--zs-default-bgcolor-light-rgb) / 60%);
    box-shadow: 0 0 3px rgb(var(--zs-default-bgcolor-light-rgb) / 60%);
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999;
    opacity: 1;
  }

  // :input-placeholder { /* Internet Explorer 10-11 */
  //   color: red;
  // }

  &::input-placeholder {
    /* Microsoft Edge */
    color: #999;
  }

  &.txt_field {
    width: 100%;
  }

  &.txt_area {
    width: 100%;
    height: 140px;
    min-height: 50px;
    max-height: 200px;
  }
}
</style>
