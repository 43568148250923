<template>
  <div class="c_about">
    <div class="c_about_wrap">
      <div class="c_about_t"><span>关于{{ this.companyNameS }}</span><label><a href="/about/" target="_blank">详情&gt;</a></label></div>
      <div class="c_about_desc">{{ this.companyNameM }}是一家专业的检验、检测及认证技术服务机构。在国内拥有5个全资子公司，20多家分支机构，通过五大业务板块，为客户量身定制技术解决方案。目前已累计服务15000+客户。
      </div>
      <img v-if="this.$store.state.sharedData.companyInfo.wxUrl" class="c_about_qrcode" :src="this.$store.state.sharedData.companyInfo.wxUrl" >
      <div class="c_about_call"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
          class="bi bi-telephone-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
            fill="white"></path>
        </svg>
        {{ this.$store.state.sharedData.companyInfo.formattedTel }}
      </div>
      <div class="c_about_im"><a href="#" target="_blank" @click.prevent="onClickIM"><svg
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-chat-left-dots"
            viewBox="0 0 16 16">
            <path
              d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
            <path
              d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>&nbsp;&nbsp;&nbsp;在线咨询</a></div>
    </div>
  </div>
</template>
<script>
import Emitter from '@/js/event-bus'

export default {
  name: 'comp-card-about',
  methods: {
    onClickIM() {
      Emitter.emit('onClickIM')
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.c_about {
  position: relative;
  width: 320px;
  height: auto;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
}

.c_about_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 25px 25px;
}

.c_about_t {
  width: 270px;
  height: 59px;
  color: #1a1a1a;
  border-bottom: 1px solid #dbdbdb;

  span {
    display: inline;
    float: left;
    width: 150px;
    height: 59px;
    font-size: 22px;
    line-height: 59px;
  }

  label {
    display: inline;
    float: right;
    width: 80px;
    height: 59px;
    line-height: 59px;
    text-align: right;
  }

  a {
    font-size: 14px;
    color: #010101;
    text-decoration: none;

    &:hover {
      color: #666;
    }
  }

}

.c_about_desc {
  width: 100%;
  height: auto;
  margin-top: 15px;
  font-size: 16px;
  line-height: 24px;
  color: #4c4c4c;
  text-align: justify;
}

.c_about_qrcode {
  width: 125px;
  height: 125px;
}

.c_about_call {
  width: 100%;
  height: 46px;
  margin-top: 22px;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  color: #fff;
  text-align: center;
  background-color: var(--zs-default-color);
  border-radius: 4px;
}

.c_about_im {
  width: 100%;
  height: 46px;
  margin-top: 18px;
  text-align: center;

  /* stylelint-disable-next-line no-descending-specificity */
  a {
    display: block;
    height: 44px;
    font-size: 18px;
    font-weight: bold;
    line-height: 46px;
    color: #1a1a1a;

    // background: url("@/assets/images/ico_chat.png") 76px 14px no-repeat;
    border: 1px solid #313131;
    border-radius: 4px;

    &:hover {
      color: #fff;
      background-color: var(--zs-default-color);
      border-color: var(--zs-default-color);

      >svg {
        fill: #fff
      }
    }
  }
}</style>
