<template>
  <div class="banner_w100">
    <div class="banner_wrap">
      <div id="main-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <!-- Indicators -->
        <ul id="main-carousel-indicators" class="carousel-indicators">
          <li data-bs-target="#main-carousel" data-bs-slide-to="0" class="main-item active" aria-current="true"
            aria-label="Slide 1"></li>
          <li data-bs-target="#main-carousel" data-bs-slide-to="1" class="main-item" aria-label="Slide 2"></li>
          <li data-bs-target="#main-carousel" data-bs-slide-to="2" class="main-item" aria-label="Slide 3"></li>
        </ul>
        <!-- Wrapper for slides -->
        <div class="carousel-inner">
          <div class="carousel-item h648 active" data-bs-interval="3000">
            <a :href="getBannerJumpUrl(0)">
              <img src="../assets/images/banner-1.jpg" class="d-block w-100" alt="First slide" /></a>
          </div>
          <div class="carousel-item h648" data-bs-interval="3000">
            <a :href="getBannerJumpUrl(1)">
              <img src="../assets/images/banner-2.jpg" class="d-block w-100" alt="Second slide" /></a>
          </div>
          <div class="carousel-item h648" data-bs-interval="3000">
            <a :href="getBannerJumpUrl(2)">
              <img src="../assets/images/banner-3.jpg" class="d-block w-100" alt="Third slide" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-home-banner',
  props: ['apiData'],
  methods: {
    getBannerJumpUrl(index) {
      if (this.apiData[index]) {
        return '/all_products/product_detail/' + this.apiData[index]
      }
      return '/all_products'
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
// .carousel-xxx override
#main-carousel {
  position: relative;
  z-index: 0;
  width: 2560px;
  height: 648px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  list-style: none;

  .carousel-item.h648 {
    width: 100%;
    height: 648px;

    img {
      // width: 100%;
      width: 2560px;
      height: 648px;
    }
  }
}

#main-carousel-indicators {
  &.carousel-indicators {
    /* background-color: #000; */
    position: absolute;
    right: 50%;
    bottom: 30px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 300px;
    margin-right: 0%;
    margin-left: 0%;
    transform: translateX(calc(min(50vw, 1280px) - 50px));

    /* @media (min-width: 1440px) and (max-width: 1920px) {} */

    // @media (min-width: 1280px) and (max-width: 1439px) {
    //   /* margin-right: 0%; */
    // }

    // @media (min-width: 1001px) and (max-width: 1279px) {
    //   /* margin-right: 0%; */
    // }
  }

  &.carousel-indicators [data-bs-target][data-bs-slide-to] {
    width: 40px;
    transition: width 0.2s ease-in;
  }

  &.carousel-indicators li.active[data-bs-target] {
    width: 80px;
    background-color: var(--zs-default-color);
  }
}

.banner_w100 {
  position: relative;
  z-index: 99;
  width: 100%;
  height: 648px;
  overflow: hidden;
}

.banner_wrap {
  position: relative;
  left: 50%;
  width: 2560px;
  height: 648px;
  margin-left: -1280px;
  overflow: hidden;

  // transform: translateX(-50%);
}
</style>
