<template>
  <Banner t1="服务项目" t2="行业深耕，客户至上" bg-cls="banner_bg_1" txt-cls="align_r" />
  <div class="product_main">
    <div class="wrap clearfix" style="width: calc(16 * var(--px100));padding-bottom: var(--px20);margin-top: var(--px50);">
      <div class="content_left">
        <div class="ls_wrap">
          <ul>
            <li v-for="(item, index) in apiData.records" :key="index"><a
                :href="'/all_products/product_detail/' + (item.id ?? '')" target="_blank">
                <div class="li_wrap">
                  <ProductCard :t1="item.name1" :t2="item.name2" :img-url="item.iconUrl" />
                </div>
              </a></li>
          </ul>
        </div>
        <nav aria-label="all products" style="padding: var(--px20) 0;">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" :href="getPrevUrl()" aria-label="Previous">
                <span aria-hidden="true">&lsaquo;</span>
              </a>
            </li>
            <li v-for="(item, index) in Array(apiData.pages)" :class="getPageItemClass(index)" :key="index">
              <a class="page-link" :href="getPageItemUrl(index)">{{ index + 1 }} </a>
            </li>
            <li class="page-item">
              <a class="page-link" :href="getNextUrl()" aria-label="Next">
                <span aria-hidden="true">&rsaquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="content_right">
        <SideboxFloat>
          <AboutCard />
          <RightBoxWikiList style="padding-bottom: var(--px20);" tt="认证咨询百科" :list="randomWikiList" />
        </SideboxFloat>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/CompBanner.vue'
import ProductCard from '@/components/CompCardProduct.vue'
import AboutCard from '@/components/CompCardAbout.vue'
import RightBoxWikiList from '@/components/CompSideboxListTitle.vue'
import SideboxFloat from '@/components/CompSideboxFloatWrap.vue'
import { useMeta } from 'vue-meta'

export default {
  name: 'ShowProducts',
  computed: {
    routeName() {
      return this.$route.name
    },
    curPage() {
      const curPage = parseInt(this.$route.params.id) || 0
      return Math.max(curPage, 1)
    }
  },
  components: {
    Banner,
    ProductCard,
    AboutCard,
    RightBoxWikiList,
    SideboxFloat
  },
  methods: {
    getPageItemClass(index) {
      if (index + 1 === this.apiData.current) {
        return 'page-item active'
      }
      return 'page-item'
    },
    getPageItemUrl(index) {
      return '/' + this.routeName + '/' + (index + 1)
    },
    getPrevUrl() {
      return '/' + this.routeName + '/' + Math.max(1, this.apiData.current - 1)
    },
    getNextUrl() {
      return '/' + this.routeName + '/' + Math.min(this.apiData.pages, this.apiData.current + 1)
    },
  },
  setup() {
    useMeta({
      title: '服务项目',
      htmlAttrs: { lang: 'zh-CN', amp: true }
    })
  },
  created() {
    this.$store.$api.postJson('projectList', { curPage: this.curPage, pageSize: 12 }).then(
      data => {
        // //console.log('### data => \n', data)
        this.apiData = data?.pageData
        this.randomWikiList = data?.randomWikiList
        if (this.randomWikiList.length > 6) this.randomWikiList = this.randomWikiList.slice(0, 6)
        this.randomWikiList.forEach(item => {
          item.url = '/wiki/wiki_detail/' + item.id
        })
      })
  },
  mounted() {
    // //console.log(this.$route.path, ', => mounted, ', this.$route.params, this.curPage)
  },
  data() {
    return {
      apiData: { pages: 1, size: 12, current: 1, records: [] },
      randomWikiList: {}
    }
  },
}
</script>

<style lang="scss" scoped>
.product_main {
  position: relative;
  width: 100%;
  max-width: 2560px;
  overflow: hidden;

  // background: url("../assets/images/showcase-bg-2.jpg") center/cover no-repeat;
}

.content_left {
  display: flex;
  flex-direction: column;
  float: left;
  width: 1224px;
}

.content_right {
  float: right;
  width: 320px;
}

.ls_wrap {
  width: 1224px;
  clear: both;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      float: left;
      width: calc(280px + 26px);
      height: calc(340px + 24px);

      // margin-right: 26px;
      background-color: unset;
    }

    li a {
      text-decoration: none;
      text-transform: none;
    }
  }

}

.li_wrap {
  position: relative;
  width: 280px;
  height: 340px;
  background-color: #f5f5f5;
}
</style>
