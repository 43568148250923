<template>
  <div class="all_cases">
    <div class="wrap">
      <div class="zs_case_tt">案例中心</div>
      <div class="zs_case_desc">行业深耕，客户至上</div>
      <div class="zs_case_content">
        <div class="ls">
          <div class="temp_wrap">
            <split-carousel :="options" class="carousel-dark">
              <template #left>
                <div id="zs-case-prev-icon">
                  <span class="carousel-control-prev-icon extended"></span>
                </div>
              </template>
              <template #right>
                <div id="zs-case-next-icon">
                  <span class="carousel-control-next-icon extended"></span>
                </div>
              </template>
              <split-carousel-item v-for="item in apiData" :key="item.id">
                <ShowcaseCard btn-title="详情" :btn-url="'/all_cases/case_detail/' + item.id"
                  :img-url="item.casePicture?.picUrl" :title="item.title" />
              </split-carousel-item>
            </split-carousel>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { SplitCarousel, SplitCarouselItem } from 'vue-split-carousel'
import ShowcaseCard from './CompCardShowCase.vue'
import Rem from '../js/rem'

export default {
  name: 'comp-home-all-cases',
  props: ['apiData'],
  components: {
    SplitCarousel,
    SplitCarouselItem,
    ShowcaseCard,
  },
  data() {
    return {
      options: {
        displayAmount: 4,
        itemWidth: (328.0 / 16 * Rem.getRemSize()),
        height: (409.0 / 16 * Rem.getRemSize()),
        autoplay: false
      }
    }
  }
}
</script>

<style lang="scss">
// override 3rd-party default
.carousel-dark {
  .split-carousel__left,
  .split-carousel__right {
    position: absolute;
    align-self: center;
    padding: 0;
  }

  .split-carousel__left {
    left: -42px;
  }

  .split-carousel__right {
    right: -42px;
  }
}
</style>

<style lang="scss" scoped>
.temp_wrap {
  position: relative;
  width: 1424px;

  // overflow: hidden
}

.carousel-dark {
  span.extended {
    height: 409px;
    cursor: pointer;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  &:hover {
    transition: all 0.5s;
    transform: scale(1.5);
  }
}

.all_cases {
  width: 100%;
  max-width: 2560px;
  height: 669px;
  padding-top: 0;
  overflow: hidden;
  background: url("../assets/images/main-bg-3.jpg") center/cover no-repeat;
}

.zs_case_tt {
  width: 1400px;
  padding-top: 62px;
  font-weight: bold;
  color: var(--zs-t1-color);
  text-align: center;
  @include text-single-line-min(48px);
}

.zs_case_desc {
  width: 1400px;
  height: 3em;
  font-size: 18px;
  line-height: 3;
  color: #999;
  text-align: center;
}

.zs_case_content {
  position: relative;
  left: -12px;
  width: 1400px;
  height: 450px;
  margin: 14px auto auto;
  clear: both;

  >.ls {
    width: 1400px;
  }
}
</style>
