<template>
  <div class="zs_card">
    <div class="c_img"><img :src="imgUrl"></div>
    <div class="c_tt">{{ title ?? '' }}</div>
    <label class="more more_small"><a :href="btnUrl" target="_blank">{{ btnTitle ?? '更多' }} &gt;</a></label>
  </div>
</template>

<script>
export default {
  name: 'comp-product-card',
  props: ['btnTitle', 'btnUrl', 'title', 'imgUrl'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.zs_card {
  position: relative;
  width: 328px;
  height: 409px;
  overflow: hidden;
  background-color: #fff;

  .c_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 328px;
    height: 262px;
  }

  .c_img>img {
    width: 100%;
    height: 100%;
  }

  label {
    position: absolute;
    top: 342px;
    left: 20px;
  }

  .c_tt {
    position: absolute;
    top: 292px;
    left: 20px;
    display: flex;
    align-items: center;
    width: 300px;
    line-height: 1.5 !important;
    color: #030227;
    @include text-single-line-min(18px);
  }

}
</style>
