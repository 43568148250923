export default {
  init: function () {
    // yanhui old: qWmCfqDAdPBzy3YoHxGnimKT
    // vcM72RPfaB2Wqcqq7QBli94s4GUyOiWY
    // by0OTdhUGo3wYuMojowmKpWe
    const AK = '86adeb480735a32ac0417bd672316542'
    const BMapURL = 'https://api.map.baidu.com/api?v=3.0&ak=' + AK + '&s=1&callback=onBMapCallback'
    return new Promise((resolve, reject) => {
      if (typeof BMap !== 'undefined') {
        // //console.log('Return existing instance...')
        resolve(window.BMap)
        return true
      }
      // 百度地图异步加载回调处理
      window.onBMapCallback = function () {
        // //console.log('BaiduMap JS sdk inited...')
        // eslint-disable-next-line
        resolve(BMap)
      }

      // 插入script脚本
      const scriptNode = document.createElement('script')
      scriptNode.setAttribute('type', 'text/javascript')
      scriptNode.setAttribute('src', BMapURL)
      document.body.appendChild(scriptNode)
    })
  },
  createCustomAnnotationCls() {
    const BMap = window.BMap
    // 定义自定义覆盖物的构造函数
    function CircleOverlay(center, length, color) {
      this._center = center
      this._length = length
      this._color = color
    }
    // 继承API的BMap.Overlay
    CircleOverlay.prototype = new BMap.Overlay()

    // 实现初始化方法
    CircleOverlay.prototype.initialize = function (map) {
      // 保存map对象实例
      this._map = map
      // 创建div元素，作为自定义覆盖物的容器
      const div = document.createElement('div')
      div.style.position = 'absolute'
      // 可以根据参数设置元素外观
      div.style.width = this._length + 'px'
      div.style.height = this._length + 'px'
      div.style.display = 'flex'
      div.style.justifyContent = 'center'
      div.style.alignItems = 'center'

      const div1 = document.createElement('div')
      div1.id = 'bmap-circle-outer'
      div1.style.width = div1.style.height = '100%'
      if (this._color.includes('rgb')) {
        div1.style.background = 'rgb(' + this._color + ' / 50%)' // 50% alpha
      } else {
        div1.style.background = this._color + '7F' // 50% alpha
      }
      div1.style.borderRadius = '50%'
      div.appendChild(div1)

      const div2 = document.createElement('div')
      div2.id = 'bmap-circle-inner'
      div2.style.position = 'absolute'
      div2.style.width = div2.style.height = '18px'
      if (this._color.includes('rgb')) {
        div2.style.background = 'rgb(' + this._color + ')'
      } else {
        div2.style.background = this._color
      }
      div2.style.borderRadius = '50%'
      div.appendChild(div2)

      // 将div添加到覆盖物容器中
      map.getPanes().markerPane.appendChild(div)
      // 保存div实例
      this._div = div
      // 需要将div元素作为方法的返回值，当调用该覆盖物的show、
      // hide方法，或者对覆盖物进行移除时，API都将操作此元素。
      return div
    }

    // 实现绘制方法
    CircleOverlay.prototype.draw = function () {
      // 根据地理坐标转换为像素坐标，并设置给容器
      const position = this._map.pointToOverlayPixel(this._center)
      this._div.style.left = position.x - this._length / 2 + 'px'
      this._div.style.top = position.y - this._length / 2 + 'px'
    }

    return CircleOverlay
  },
  createCustomInfoBoxCls() {
    const BMap = window.BMap
    // 复杂的自定义覆盖物
    function ComplexCustomOverlay(point, text, mouseoverText) {
      this._point = point
      this._text = text
      this._overText = mouseoverText
    }
    ComplexCustomOverlay.prototype = new BMap.Overlay()
    ComplexCustomOverlay.prototype.initialize = function (map) {
      this._map = map
      const div = this._div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.zIndex = BMap.Overlay.getZIndex(this._point.lat)
      div.style.backgroundColor = '#FFF'
      div.style.color = 'white'
      div.style.height = '76px'
      div.style.width = '168px'
      div.style.boxShadow = '0px 5px 10px 1px #AAAAAA'
      div.style.display = 'flex'
      div.style.justifyContent = 'center'
      div.style.transform = 'translateX(-50%)'
      // div.style.background = 'url(/img/contact-logo.png) center/contain no-repeat'
      div.style.MozUserSelect = 'none'
      const img = document.createElement('img')
      img.src = '/img/contact-logo.png'
      img.style.objectFit = 'contain'
      img.style.padding = '18px'
      img.style.width = 'auto'
      div.appendChild(img)

      // div.onmouseover = function () {}
      // div.onmouseout = function () {}

      map.getPanes().labelPane.appendChild(div)

      return div
    }
    ComplexCustomOverlay.prototype.draw = function () {
      const map = this._map
      const pixel = map.pointToOverlayPixel(this._point)
      this._div.style.left = pixel.x + 'px'
      this._div.style.top = pixel.y - 116 + 'px'
    }
    return ComplexCustomOverlay
  }
}
