import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = 'https://www.shyanhuizx.cn:8080/textile/'
// axios.defaults.baseURL = 'http://47.98.195.175:8080/textile/'
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config
}, function (error) {
  // //console.log('### request error: ', error)
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  const { data: { code = 0, msg = 'null' } = {} } = response
  if (code === 200) {
    return response
  } else {
    validateStatus(response)
    // //console.log('### response error: ', msg)
    return Promise.reject(msg)
  }
}, function (error) {
  // //console.log('### response error: ', error)
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error?.response?.data?.msg) {
    error.message = error.response.data.msg
  }
  return Promise.reject(error)
})

function validateStatus(response) {
  // if (response && (response.status === 200 || response.status === 304)) { }
  // //console.log('### response status ', response.status, response.statusText)
}

export default {
  postJson(url, data) {
    return axios.post(
      url,
      data
    ).then(response => {
      return response.data?.data
    })
  },
  file(url, data) {
    return axios({
      method: 'post',
      url,
      data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
  },
  post(url, data) {
    return axios({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  },
  get(url, params) {
    return axios({
      method: 'get',
      url: url,
      params,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
  }
}
