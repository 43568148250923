<template>
  <div class="wiki_rb">
    <div class="wiki_rb_T"><span>{{ tt }}</span><label><a href="/all_products/">更多&gt;</a></label></div>
    <div class="wiki_rb_ls_prod">
      <ul>
        <li :class="'rk_ico_' + ++index" v-for="(item, index) in list" :key="index">
          <a :href="item.url" target="_blank">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comp-sidebox-list-icon',
  props: ['list', 'tt', 'moreUrl'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.wiki_rb {
  width: 260px;
  padding: 10px 30px 0;
  margin-top: 20px;
  overflow: hidden;
  background-color: #f7f7f7;
}

.wiki_rb_T {
  position: relative;
  width: 260px;
  height: 62px;
  text-indent: 18px;
  border-bottom: 1px solid #dbdbdb;
  @include left-indicator(8px, 35px, var(--zs-default-color), 14px);

  span {
    display: inline;
    float: left;
    width: 180px;
    height: 62px;
    font-size: 22px;
    line-height: 62px;
    color: #1a1a1a;
  }

  label {
    float: right;
    width: 80px;
    height: 62px;
    line-height: 62px;
    text-align: right;

    a {
      font-size: 14px;
      color: #666;
      text-decoration: none;
    }
  }
}

@mixin wiki-ls-item-bg($index) {
  #{"&.rk_ico_" + $index} {
    background: url("@/assets/images/ico_class_" + $index + ".png") 1px 10px no-repeat;
  }
}

.wiki_rb_ls_prod {
  width: 260px;
  padding-top: 12px;
  padding-bottom: 22px;
  margin: 0 auto;
  overflow: hidden;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      float: left;
      width: 252px;
      height: 43px;
      padding-left: 28px;
      border-bottom: 1px solid #ededed;

      @include wiki-ls-item-bg(1);
      @include wiki-ls-item-bg(2);
      @include wiki-ls-item-bg(3);
      @include wiki-ls-item-bg(4);
      @include wiki-ls-item-bg(5);
      @include wiki-ls-item-bg(6);
      @include wiki-ls-item-bg(7);
      @include wiki-ls-item-bg(8);

      >a {
        display: block;
        width: 252px;
        height: 36px;
        font-size: 16px;
        line-height: 36px;
        color: #4c4c4c;
        text-decoration: none;

        &:hover {
          font-weight: bold;
          color: #1a1400;

          // background: url("images/baike/row.png") right center no-repeat;
        }
      }
    }
  }
}
</style>
